import { useEffect, useState } from "react"
import { EventDTO } from "../../DTO/EventDTO"
import ItemLocationDTO from "../../DTO/ItemLocationDTO"
import { Status } from "../day/dayView"
import Spinner from "../../../components/Spinner/Spinner"
import Location from "./Location"
import { AlignJustify } from "react-feather"
import LocationList from "./LocationList"

export default function LocationSelect(props: {
  Event?: EventDTO
  onSelect?: () => void,
  selectButton?: string,
  shortInfo?: boolean,
}) {
  const [SelectedLocation, setSelectedLocation] = useState(new ItemLocationDTO({
    eventId: props.Event?.id || -1
  }))
  const [status, setStatus] = useState(Status.loading)
  useEffect(() => {
    SelectedLocation.getFromServer().then(() => {
      console.log('Done: ', SelectedLocation)
      setStatus(Status.standard)
    })
  }, [])
  return <div className='STLocationSelect'>
    {
      status === Status.loading && <Spinner />
    }
    {
      status !== Status.loading &&
      <>
        {
          !props.selectButton &&
          <div className='flex w100 justify-content-space-between'>
            {
              SelectedLocation.id > -1 ? <Location
                item={SelectedLocation}
                className={`flex-grow`}
                onClick={() => {
                  if (!props.shortInfo) {
                    setStatus(status === Status.edit ? Status.standard : Status.edit)
                  }
                }}
              /> : <button
                onClick={() => {
                  if (!props.shortInfo) {
                    setStatus(status === Status.edit ? Status.standard : Status.edit)
                  }
                }}
                className='w3-button'
              >Kein Veranstaltungsort ausgewählt</button>
            }
            {
              !props.shortInfo &&
              <button
                title="Veranstaltungsort wählen"
                className={`w3-button ${status === Status.edit ? 'w3-border w3-border-blue' : ''}`}
                onClick={() => {
                  setStatus(status === Status.edit ? Status.standard : Status.edit)
                }}
              ><AlignJustify /></button>
            }
          </div>
        }
        {
          props.selectButton &&
          <button
            title="Person wählen"
            className={`w3-button ${status === Status.edit ? 'w3-border w3-border-blue' : ''}`}
            onClick={() => {
              setStatus(status === Status.edit ? Status.standard : Status.edit)
            }}
            dangerouslySetInnerHTML={{__html: props.selectButton}}
          ></button>
        }
        {
          status === Status.edit &&
          <LocationList
            className='w3-margin-top'
            selected={SelectedLocation.id}
            onSelect={async (id, location) => {
              console.log('on select', id, location)
              setStatus(Status.loading)
              setSelectedLocation(location)
              setStatus(Status.standard)
              if (props.Event) {
                await props.Event.setLocation(id)
              }
              if (props.onSelect) {
                props.onSelect()
              }
            }}
          />
        }
      </>
    }
  </div>
}
