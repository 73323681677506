import React, { useEffect, useRef, useState } from 'react';
import BuildingBlocksDTO from '../../DTO/BuildingBlocksDTO';
import { PublishKind } from '../../models/publishKinds';
import Spinner from '../../../components/Spinner/Spinner';
import DayView, { DisplayMode, Status } from '../day/dayView';
import { DayDTO } from '../../DTO/DayDTO';
import { ObjectKind, objectKindsArr } from '../../models/objectKinds';
import ModuleView from '../moduleView';
import ModuleDTO from '../../DTO/ModuleDTO';
import { TrainingItemDTO } from '../../DTO/TrainingItemDTO';
import TrainingitemView from '../trainingitemView';
import { DisplayKind, MaterialItemDTO, Materialkinds } from '../../DTO/MaterialItemDTO';
import MaterialView from '../materialView';
import { Plus } from 'react-feather';
import InputB from '../../../components/InputB/InputB';
import { TagFilter } from './TagFilter';
import { IProp } from '../../DTO/PropDTO';
import { InfinitScroll } from '../../../services/InfinitScroll';
import { TagType } from '../../DTO/TagDTO';

export function makeAssetFilter(kind: string): IProp[] {
  if (kind === '') {
    return []
  }
  return [{
    id: -1,
    key1: 'data',
    key2: 'materialKind',
    value1: kind,
    value2: ''
  }]
}

export function BuildingBlocks(props: {
  editItem?: (item: any, objecKind: ObjectKind) => void,
  reloadTrigger: number,
  showEmptyElement?: boolean,
}) {
  const [buildingBlocksDTO] = useState(new BuildingBlocksDTO({
    elementKindPage: ObjectKind.module,
    showEmptyElement: props.showEmptyElement,
  }));
  const [bbpage, setBbpage] = useState('module');
  const [status, setStatus] = useState(Status.loading);
  const [listStatus, setListStatus] = useState(Status.standard);
  const [displayLike, setDisplayLike] = useState(DisplayKind.standard);
  const [currentNeedle, setCurrentNeedle] = useState('');
  const [rerender, setRerender] = useState(0);
  const [assetFilter, setAssetFilter] = useState('');
  const [tagIds, setTagIds] = useState('');
  const [infinitScroll] = useState(new InfinitScroll({
    ref: useRef(null),
    cb: async () => {
      // What to do, if we are at the end of the list?
      const newPage = infinitScroll.incrementAndLock()
      if (newPage === -1) { return }
      setStatus(Status.loadMore)
      const newElementCount = await buildingBlocksDTO.setElementKindPage(infinitScroll.getPage(), undefined, undefined)
      if (newElementCount) {
        infinitScroll.unlock()
      }
      setStatus(Status.standard)
      // Now load new content - then set Status to "standard" and unlock
      // If nothing is loaded - lock permanently
    }
  }))

  function setStatusStandard(context: string) {
    if (status === Status.standard) {
      setRerender(rerender + 1);
      setStatus(Status.standard);
    } else {
      setStatus(Status.standard);
    }
  }
  function setStatusLoading(context: string) {
    if (status === Status.loading) {
    } else {
      setStatus(Status.loading);
    }
  }

  const reload = () => {
    setStatusLoading('reload')
    infinitScroll.setPage(0)
    infinitScroll.lock()
    buildingBlocksDTO.setElementKindPage(infinitScroll.getPage(), undefined, undefined, currentNeedle, makeAssetFilter(assetFilter)).then(() => {
      setStatusStandard('reload')
      infinitScroll.unlock()
    });
  };
  useEffect(() => {
    reload();
  }, [props.reloadTrigger]);
  
  const search = async (needle: string) => {
    setCurrentNeedle(needle);
    setStatusLoading('search')
    infinitScroll.setPage(0)
    infinitScroll.lock()
    await buildingBlocksDTO.setElementKindPage(infinitScroll.getPage(), undefined, undefined, needle, makeAssetFilter(assetFilter));
    setStatusStandard('search')
    infinitScroll.unlock()
  };

  const tagTypes: TagType[] = buildingBlocksDTO.elementKindPage === ObjectKind.module ? ['subjectTags', 'moduleKind', 'language', 'workStateTag'] : ['subjectTags', 'language', 'workStateTag', 'priorityA']
  
  return <>
    <div className='flex'>
      { objectKindsArr.map((item, index) => {
        return <div
          key={`right-menu-item-${item.key}`}
          className={`PlaningMenuItem ${bbpage === item.key ? 'PlaningMenuItem-Active' : ''}`}
          onClick={async () => {
            setStatusLoading('menu-click')
            setTagIds('');
            setAssetFilter('');
            setCurrentNeedle('');
            setDisplayLike(DisplayKind.standard);
            buildingBlocksDTO.tagIdString = '';
            setBbpage(item.key);
            infinitScroll.setPage(0)
            infinitScroll.lock()
            await buildingBlocksDTO.setElementKindPage(infinitScroll.getPage(), item.id, undefined, '', []);
            setStatusStandard('menu-click')
            infinitScroll.unlock()
          }}
        >{item.name}</div>;
      }) }
    </div>
    <div className='BuildBlockList scrollContent flex flex-column'>
      <div className='buildBlocksFilter'>
        <div className='flex'>
          <button
            className={`addButton`}
            onClick={() => {
              if (props.editItem) {
                switch (buildingBlocksDTO.elementKindPage) {
                  case ObjectKind.materialItem:
                    props.editItem(new MaterialItemDTO({ id: -1, name: currentNeedle, publishKind: PublishKind.isTemplate }), buildingBlocksDTO.elementKindPage);
                    break;
                  case ObjectKind.trainingItem:
                    props.editItem(new TrainingItemDTO({ id: -1, name: currentNeedle, publishKind: PublishKind.isTemplate }), buildingBlocksDTO.elementKindPage);
                    break;
                  case ObjectKind.module:
                    props.editItem(new ModuleDTO({ id: -1, name: currentNeedle, publishKind: PublishKind.isTemplate }), buildingBlocksDTO.elementKindPage);
                    break;
                  case ObjectKind.day:
                    props.editItem(new DayDTO({ id: -1, name: currentNeedle, publishKind: PublishKind.isTemplate }), buildingBlocksDTO.elementKindPage);
                    break;
                  default:
                    alert(`Kann Typ ${buildingBlocksDTO.elementKindPage} nicht finden`);
                }
              }
            }}
          ><Plus /></button>
          <InputB
            delay={1000}
            value={currentNeedle}
            placeholder={'Suche'}
            returnVal={(needle) => {
              search(needle);
            }}
            clearButton={true} />
        </div>
        <TagFilter
          tagTypes={tagTypes}
          bbpage={bbpage}
          onChange={async (tagIdString) => {
            setStatusLoading('tagFilter')
            setTagIds(tagIdString);
            buildingBlocksDTO.tagIdString = tagIdString;
            infinitScroll.setPage(0)
            infinitScroll.lock()
            await buildingBlocksDTO.setElementKindPage(infinitScroll.getPage(), undefined, undefined, currentNeedle, makeAssetFilter(assetFilter));
            setStatusStandard('tagFilter')
            infinitScroll.unlock()
          }}
          tagIds={tagIds} />
        { status === Status.loading &&
          <Spinner />
        }
        {status !== Status.loading && buildingBlocksDTO.elementKindPage === ObjectKind.materialItem &&
          <div
            className='flex flex-wrap'
          >
            {[...[{ title: 'Alle', value: '', displayKind: DisplayKind.standard }], ...Materialkinds].map((mk, index) => {
              return <button
                key={`materialkind-${index}`}
                className={`w3-button ${mk.value === assetFilter ? 'w3-blue' : ''}`}
                onClick={async () => {
                  setListStatus(Status.loading)
                  infinitScroll.setPage(0)
                  infinitScroll.lock()
                  setAssetFilter(mk.value)
                  try {
                    await buildingBlocksDTO.setElementKindPage(infinitScroll.getPage() ,undefined, undefined, currentNeedle, makeAssetFilter(mk.value));
                  } catch(e) {
                    console.log('Error while loading list', e)
                  }
                  setListStatus(Status.standard)
                  setAssetFilter(mk.value);
                  setDisplayLike(mk.displayKind || DisplayKind.standard);
                  infinitScroll.unlock()
                }}
              >
                {mk.title}
              </button>
            })}
          </div>}
      </div>
      <div
        ref={infinitScroll.getRef()}
        className={`flex-grow ${displayLike === DisplayKind.gallerie ? 'RightDrawerGallery w3-row align-items-stretch flex flex-wrap' : ''}`}
      >
        {listStatus === Status.loading &&
          <Spinner className={`w100`} />
        }
        {listStatus !== Status.loading && status !== Status.loading &&
          buildingBlocksDTO.ShowItems().map((item, index) => {
            switch (item.objectKind) {
              case ObjectKind.day:
                if (item instanceof DayDTO) {
                  return <DayView
                    day={item}
                    key={`building-block-item-${item.id}-${index}`}
                    displayMode={DisplayMode.template}
                    parentObjectKind={ObjectKind.seminarday}
                    parentPublishKind={PublishKind.isTemplate}
                    fullScreenEdit={() => {
                      if (props.editItem) {
                        props.editItem(item, buildingBlocksDTO.elementKindPage);
                      }
                    }}
                    rerender={async () => {
                      setStatusLoading('dayview')
                      infinitScroll.setPage(0)
                      infinitScroll.lock()
                      await buildingBlocksDTO.getElementsFromServer(0, ObjectKind.module, PublishKind.isTemplate);
                      setStatusStandard('dayview')
                      infinitScroll.unlock()
                    }} />;
                }
                break;
              case ObjectKind.module:
                if (item instanceof ModuleDTO) {
                  return <ModuleView
                    module={item}
                    key={`building-block-item-${item.id}-${index}`}
                    displayMode={DisplayMode.template}
                    parentObjectKind={ObjectKind.module}
                    parentPublishKind={PublishKind.isTemplate}
                    fullScreenEdit={() => {
                      if (props.editItem) {
                        props.editItem(item, buildingBlocksDTO.elementKindPage);
                      }
                    }}
                    rerender={async () => {
                      setStatus(Status.loading);
                      infinitScroll.setPage(0)
                      infinitScroll.lock()
                      await buildingBlocksDTO.getElementsFromServer(0, ObjectKind.module, PublishKind.isTemplate);
                      setStatusStandard('moduleview')
                      infinitScroll.unlock()
                    }} />;
                }
                break;
              case ObjectKind.trainingItem:
                if (item instanceof TrainingItemDTO) {
                  return <TrainingitemView
                    trainingitem={item}
                    key={`building-block-item-${item.id}-${index}`}
                    parentObjectKind={ObjectKind.daymodule}
                    parentPublishKind={PublishKind.isTemplate}
                    displayMode={DisplayMode.template}
                    fullScreenEdit={() => {
                      if (props.editItem) {
                        props.editItem(item, buildingBlocksDTO.elementKindPage);
                      }
                    }} />;
                }
                break;
              case ObjectKind.materialItem:
                if (item instanceof MaterialItemDTO) {
                  return <MaterialView
                    materialItem={item}
                    key={`building-block-item-${item.id}-${index}`}
                    parentObjectKind={ObjectKind.daymodule}
                    parentPublishKind={PublishKind.isTemplate}
                    displayMode={DisplayMode.template}
                    displayKind={displayLike}
                    fullScreenEdit={() => {
                      if (props.editItem) {
                        props.editItem(item, buildingBlocksDTO.elementKindPage);
                      }
                    }}
                    rerenderRightSide={reload} />;
                }
                break;
            }
            return <div>Unbekannter Typ {item.objectKind}</div>;
          })}
          {
            status === Status.loadMore &&
            <div className='textCenter'>
              <Spinner mini={true} />
              <div>Lade weitere Elemente</div>
            </div>
          }
      </div>
    </div>
  </>
}
