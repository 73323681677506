import { useDrag } from "react-dnd";
import { ObjectKind } from "../models/objectKinds";
import { Copy, Edit, Eye, GitMerge, Trash } from "react-feather";
import { useEffect, useState } from "react";
import InputA from "../../components/InputA/InputA";
import { DisplayMode } from "./day/dayView";
import { DropZone } from "../elements/DropZone";
import { DisplayKind, MaterialItemDTO } from "../DTO/MaterialItemDTO";
import { PublishKind } from "../models/publishKinds";
import { parseStringToServer } from "../../services/TextConverter";
import { DisplayScope } from "../DTO/GenericDTO";
import { EditField } from "./EditField";
import FileManager from "./FileManager/FileManager";
import './pt.scss'
import './day/dayView.scss'
import { DisplayDate } from "../../services/DateTime";
import Spinner from "../../components/Spinner/Spinner";
import H2Edit from "../elements/H2Edit";
import Credentials from "./Generic/Credentials";

enum Status {
  standard,
  editName,
  details,
  loading,
}

export default function MaterialView(props: {
  materialItem: MaterialItemDTO,
  displayMode: DisplayMode,
  parentPublishKind: PublishKind,
  parentObjectKind: ObjectKind,
  trash?: (item: MaterialItemDTO) => void,
  rerender?: () => void,
  rerenderRightSide?: () => void,
  position?: number,
  addItem?: (position: number, item: MaterialItemDTO) => void,
  fullScreenEdit?: () => void,
  close?: () => void,
  displayKind?: DisplayKind,
  id?: number,
}) {
  //const item = props.materialItem
  const [item, setItem] = useState(props.materialItem || new MaterialItemDTO({id: -1}))
  const [{isDragging}, drag] = useDrag(() => ({
    type: ObjectKind.materialItem + '',
    item: item,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag(monitor) {
      return !!props.fullScreenEdit
    },
  }), [42])
  const [status, setStatus] = useState(item.id < 0 && (props?.id || -1) > -1 ? Status.loading : Status.standard)
  const [displayKind, setDisplayKind] = useState(props.displayKind)
  useEffect(() => {
    setStatus(Status.loading)
    if (props.displayMode === DisplayMode.edit) {
      props.materialItem.getFromServer().then(() => {
        setItem(props.materialItem)
        setStatus(item.id < 0 && (props?.id || -1) > -1 ? Status.loading : Status.standard)
      })
    } else {
      setItem(props.materialItem)
      setStatus(item.id < 0 && (props?.id || -1) > -1 ? Status.loading : Status.standard)
    }
  }, [props.materialItem.id, props.materialItem.objectKind, props.materialItem.publishKind])
  useEffect(() => {
    setDisplayKind(props.displayKind)
  }, [props.displayKind])
  useEffect(() => {
    if (props.id && props.id > -1) {
      const oldStatus = status !== Status.loading ? status : Status.standard
      setStatus(Status.loading)
      item.id = props.id as number
      item.getFromServer().then(() => {
        setTimeout(() => {
          // If this is to fast - we will not see anything:
          setStatus(oldStatus)
        }, 100)
      })
    }
  }, [props.id])
  const className = `MaterialItem id-${item.id} ${displayKind === DisplayKind.gallerie ? 'isGallerieItem' : ''} ${props.displayMode === DisplayMode.edit ? '' : 'PTListItem'} ${displayKind === DisplayKind.gallerie ? 'w3-col s12 m6 l6' : ''} ${(isDragging) ? 'dragging' : ''}`
  if (status === Status.loading) {
    return <div
      className={className}
    > <Spinner />
    </div>
  }
  return <div
    className={className}
    title={item.getFirstTagNameByKind('materialNameTag') + ' - ' + item.getName()}
    ref={drag}
  >
    <div className={'lineContent'}>
      <div className='stretch'>
        {props.displayMode === DisplayMode.edit &&
          <H2Edit
            value={item.getName()}
            onChange={async (rv) => {
              setStatus(Status.editName)
              await item.setName(rv)
              setStatus(Status.standard)
            }}
            oldValue={item.getUpName(true)}
            resetToOldValue={async () => {
              await item.setName('')
              if (props.rerender) {
                props.rerender()
              }
            }}
          />
        }
        {props.displayMode === DisplayMode.view &&
          <>
            <h2>{item.getName()}</h2>
            <div className=''>{item.getFirstTagNameByKind('materialNameTag')}</div>
          </>
        }
        {props.displayMode !== DisplayMode.edit && props.displayMode !== DisplayMode.view && displayKind !== DisplayKind.gallerie &&
          <>
            <div className=''>{item.getFirstTagNameByKind('materialNameTag')}</div>
            <div className=''>{item.getName()}</div>
          </>
        }
      </div>
      <div className='rightTools dont-print' data-html2canvas-ignore="true">
      {
        props.fullScreenEdit && item.id > -1 &&
        <Edit
          className='editItem pointer'
          size='20px'
          onClick={() => {
            if (props.fullScreenEdit) {
              props.fullScreenEdit()
            }
          }}
        />
      }
      {
        props.displayMode === DisplayMode.leftSide &&
        <Eye
          className='editItem pointer'
          size='20px'
          onClick={() => {
            if (status === Status.details) {
              setStatus(Status.standard)
            } else {
              setStatus(Status.details)
            }
          }}
        />
      }
      {
        props.displayMode !== DisplayMode.edit &&
        <GitMerge
          className='editItem pointer'
          size='20px'
          onClick={async () => {
            await item.trashPreflight('info')
          }}
        />
      }
      { props.displayMode !== DisplayMode.edit && props.displayMode !== DisplayMode.agenda && props.displayMode !== DisplayMode.leftSide && props.displayMode !== DisplayMode.view &&
          <Copy
            className='editItem pointer'
            size='20px'
            onClick={async () => {
              await item.clone(-1, undefined, `${item.name} Kopie - ${DisplayDate(new Date())}`, PublishKind.isTemplate)
              if (props.rerender) {
                props.rerender()
              }
              if (props.rerenderRightSide) {
                props.rerenderRightSide()
              }
            }}
          />
        }
        {
          props.trash && item.id > -1 &&
          <Trash
            className='delButton'
            size='20px'
            onClick={async () => {
              console.log('want to trash material - materialView', item.objectKind)
              if (
                props.trash && window.confirm('Material wirklich löschen?')
                // item instanceof MaterialItemDTO && props.trash
                // && window.confirm('Material wirklich löschen?')
              ) {
                props.trash(item)
              }
            }}
          />
        }
      </div>
    </div>
    {(displayKind === DisplayKind.gallerie) &&
    <div className="GallerieThumb floatLeft">
      <FileManager
        item={item}
        readMode={true}
        className={`innerThumb`}
        showBiggerPreview={false}
      />
      <div className='thumbTitle'>
        {item.getName()}
      </div>
    </div>
    }
    {
      (status === Status.details || props.displayMode === DisplayMode.edit || props.displayMode === DisplayMode.view) &&
      <div className='info-block'>
        {
          item.getEditFields().map((ef, index) => {
            return <EditField
              key={`ef-${item.id}-${index}`}
              field={ef}
              patch={async (efp) => {
                await item.addProp(efp.key1, efp.key2, parseStringToServer(efp.value || ''))
                return efp
              }}
              item={item}
              editMode={props.displayMode === DisplayMode.edit}
            />
          })
        }
        <Credentials item={item} />
      </div>
    }
    { (props.displayMode === DisplayMode.leftSide || props.displayMode === DisplayMode.agenda || props.displayMode === DisplayMode.agendaPlus) &&
      <DropZone
        label={``}
        position={props.position || 0}
        addItem={async (position, item) => {
          if (props.addItem) {
            props.addItem(position, item)
          }
        }}
        acceptKind={ObjectKind.materialItem}
        className='ptdropzoneHorizontal ptdropTopGeneric'
      />
    }
  </div>
}

/**
 * Is this to be displayed?
 * @param currentPublishKind
 * @param materialPublishKind 
 * return -1 if nothing shall be displayed, 0, if user can see, and 1 if user can edit
 */
export function CanBeDisplayed(currentPublishKind: PublishKind, fieldDisplayKind: DisplayScope): number {
  if (fieldDisplayKind === DisplayScope.hide) { return -1 }
  if (currentPublishKind === PublishKind.isSeminar) {
    switch (fieldDisplayKind) {
      case DisplayScope.seminar:
      case DisplayScope.templateAndSeminar:
      case DisplayScope.templateReadAndSeminar:
        return 1
      case DisplayScope.template:
        return -1
      case DisplayScope.read:
      case DisplayScope.templateAndSeminarRead:
        return 0
    }
  }
  if (currentPublishKind === PublishKind.isTemplate) {
    switch (fieldDisplayKind) {
      case DisplayScope.template:
      case DisplayScope.templateAndSeminar:
      case DisplayScope.templateAndSeminarRead:
        return 1
      case DisplayScope.seminar:
        return -1
      case DisplayScope.read:
      case DisplayScope.templateReadAndSeminar:
        return 0
    }
  }
  return -1
}


