import { DisplayScope, EditFieldKind, GenericDTO, GenericEditField, IGeneric } from "./GenericDTO";
import { ObjectKind } from "../models/objectKinds";
import DayModuleDTO, { IDayModule } from "./DayModuleDTO";
import { Setter } from "../../services/ComService";
import ModuleDTO, { IModule } from "./ModuleDTO";
import { PublishKind } from "../models/publishKinds";
import { Minutes2TimeString, TimeString2Minutes } from "../../services/DateTime";
import { GenericMaterialItemDTO, IGenericMaterialItem } from "./GenericMaterialItemDTO";
import { EqualizeItemListAndSortString, PutIdAtPosition } from "../../services/SortString";
import { IMaterialItem, MaterialItemDTO } from "./MaterialItemDTO";

export class DayDTO extends GenericDTO {
  
  modules: DayModuleDTO[] = []
  startTime = TimeString2Minutes('06:00')
  endTime = TimeString2Minutes('24:00')

  constructor(o: IDay) {
    super(o)
    this.objectKind = ObjectKind.day
  }

  public async getChildrenFromServer() {
    const url = 'spt/getChildren'
    if (this.id === -1) { return }
    const data = await Setter(url, {id: this.id})
    this.modules = this.mixOwnUpChildren(data, ObjectKind.daymodule).map(c => new DayModuleDTO(c))
    // Materials:
    this.materialItems = this.mixOwnUpChildren(data, ObjectKind.genericMaterialItem).map((item: IGenericMaterialItem) => new GenericMaterialItemDTO(item))
  }

  public async addModule(position: number, item: ModuleDTO, type: 'time' | 'position' | 'template2seminar' | 'duration') {
    console.log('dayDto addModule', position, item, type)
    const order = this.getOrderItemsWithTime()
    if (type === 'template2seminar' && item.up[0]) {
      // Neues Element erstellen
      const cloneModule = new DayModuleDTO({
        ...item,
        ...{
          // id: -1,
          publishKind: type === 'template2seminar' ? PublishKind.isSeminar : PublishKind.isTemplate,
        }
      })
      await cloneModule.clone(this.id)
      this.modules = this.modules.filter(m => m.id !== item.id)
      this.modules.push(cloneModule)
      // Sortierung korrigieren:
      await this.itemOrderReplaceItem(item.id, cloneModule.id)
      // An anderer Stelle sorgen wir dafür, dass nur das neue Element "überlebt"
      console.log('dayDto addModule return A')
      return
    }
    const oldPosition = order.findIndex(oi => item.hasId(oi.id)) // indexOf(item.id)
    if (oldPosition > -1 || this.modules.some(m => m.hasId(item.id) || item.hasId(m.id))) {
      if (oldPosition !== -1 && position > oldPosition && type === 'position') {
        position -= 1
      }
      if (type === 'time') {
        item.start = position
        await this.addItemToOrderAtTime(position, item.id)
      } else {
        await this.addItemToOrderAtPosition(position, item.id)
      }
      console.log('dayDto addModule return B')
      return
    }
    await this.linkTargetToParent({
      publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar,
      type: type,
      position: position,
      targetId: item.id,
    })
  }

  private async linkTargetToParent(options: {
    publishKind: PublishKind,
    type: string,
    position: number,
    targetId: number,
  }) {
    const type = options.type
    const position = options.position
    const respons = await Setter('spt/linkTargetToParent', {
      ...options,
      parentId: this.id,
      objectKind: ObjectKind.daymodule,
    })
    const dayModule = new DayModuleDTO(respons.item)
    this.modules.push(dayModule)
    if (type === 'time') {
      dayModule.start = position
      await this.addItemToOrderAtTime(position, dayModule.id)
    } else {
      await this.addItemToOrderAtPosition(position, dayModule.id)
    }
  }

  public async removeModule(item: ModuleDTO) {
    console.log('removeModule - before filter', this.modules)
    this.modules = this.modules.filter(m => m.id !== item.id)
    console.log('removeModule - after filter', this.modules)
    if (this.publishKind === PublishKind.isSeminar && item.publishKind === PublishKind.isTemplate) {
      // I think we do not need this anymore:
      this.addToBlacklist(item.id)
    } else {
      /*
      const replaceIdRaw = item.getPropV1('link', 'replace')
      if (replaceIdRaw !== '') {
        const replaceId = parseInt(replaceIdRaw, 10)
        await this.itemOrderReplaceItem(item.id, replaceId)
        // Load the original Data and append it to the modules - why would we do this?:
        const url = 'spt/getInfo'
        try {
          const data = await Setter(url, {id: replaceId})
          const oldModule = new DayModuleDTO(data.item as unknown as IDayModule)
          this.modules.push(oldModule)
        } catch(error) {
          console.log('Replacing by old element did not work', error)
        }
      }
      */
      if (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) {
        await item.templateTrash()
      } else {
        await item.seminarTrash()
      }
    }
  }

  public async trashDay(item: DayModuleDTO) {
    const id = item.id + 0
    // delete Day if it is of type seminar
    await item.seminarTrash()
    // remove from days list:
    this.removeItemOfOrder(id)
    this.modules = this.modules.filter(d => d.id !== id)
  }

  public getModules() {
    // Zeitliche Verteilung:
    let timePointer = this.startTime + 0
    console.log('getModules', this.modules)
    let out = this.orderedItems(this.modules) as unknown as DayModuleDTO[]
    out = out.map((i, index) => {
      let start = i.start || timePointer
      i.plannedStart = start + 0
      if (start < timePointer) {
        start = timePointer
      } else {
        timePointer = start
      }
      i.start = start
      timePointer += i.duration
      return i
    })
    return out
  }

  public getOrderItemsWithTime() {
    const list = this.order.split(' ').filter(oi => oi !== '').map(oi => {
      const raw = oi.match(/([0-9]+);([0-9]+);(.*)/)
      if (raw) {
        return {
          id: parseInt(raw[1], 10),
          time: parseInt(raw[2], 10),
          option: raw[3],
        }
      }
      return {
        id: parseInt(oi || '-1', 10) || -1,
        time: 0,
        option: '',
      }
      
    })
    const sortedList = list.sort((a,b) => {
      return a.time - b.time // this is the right way
    })
    console.log('DayDTO sortedList ', sortedList)
    return sortedList
  }

  // gibt die Items nach der vorgegebenen Reihenfolge aus. Überzählige werden am Ende ausgegeben:
  public orderedItems(items: GenericDTO[]) {
    const list = this.getOrderItemsWithTime()
    console.log('orderedItems items', items)
    // remove items from list, which are not in actual items
    // fill in actual items to list
    let out = list.filter(li => items.some(ii => ii.hasId(li.id))).map(li => items.find(ii => ii.hasId(li.id)))
    let forgotten = items.filter(ii => out.every(oi => !(oi?.hasId(ii.id))))
    out = out.concat(forgotten)
    // Add time at loadtime:
    out.forEach(outItem => {
      if (outItem) {
        const repItem = list.find(li => outItem.hasId(li.id))
        if (repItem) {
          /*
          const plannedStartTime = list.find(i => i.id === repItem.id)?.time || 0
          const repTime = repItem.time;
          */
          (outItem as ModuleDTO).start = repItem.time; // Semikolon has to be here!
          (outItem as ModuleDTO).pin = repItem.option === 'p' ? true : false
        }
      }
    })
    return out
  }

  public pinItem(id: number, unpin?: boolean, start?: number) {
    const newOrder = this.getOrderItemsWithTime().map(i => {
      if (i.id === id) {
        i.option = unpin ? 'f' : 'p'
        if (start) {
          i.time = start
        }
      }
      return i
    })
    const order = this.makeSortString(newOrder)
    this.order = order
    this.addProp('set', 'order', order)
  }

  private async itemOrderReplaceItem(oldId: number, newId: number) {
    const newOrder = this.getOrderItemsWithTime().map(i => {
      if (i.id === oldId) {
        return {...i, ...{id: newId}}
      }
      return i
    })
    const order = this.makeSortString(newOrder)
    this.order = order
    await this.addProp('set', 'order', order)
  }

  public getHourGrid(): TimeCell[] {
    const startTime = this.startTime + 0
    const endTime = this.endTime + 0
    const duration = 15
    const displayTimeInterval = 60
    let out = []
    let dayMinutes: number = startTime + 0
    while(dayMinutes < endTime) {
      out.push({
        timeProg: dayMinutes + 0,
        timeName: Minutes2TimeString(dayMinutes),
        duration: duration,
        startTime: startTime,
        displayTime: dayMinutes % displayTimeInterval === 0,
      })
      dayMinutes += duration
    }
    return out
  }

  public orderEveryUnorderedItem(items: DayModuleDTO[]) {
    // items as unknown as DayDTO[]
    const oldOrder = this.order + ''
    let itemsids = items.map((item) => {
      return {
        id: item.id,
        time: item.start || 0,
        option: '',
      }
    })
    let list = this.getOrderItemsWithTime()
    let log: number[] = []
    // remove listitems not in items:
    let list2 = list.filter(listitem => {
      if (log.indexOf(listitem.id) > -1) {
        return false
      }
      log.push(listitem.id)
      return itemsids.some(ii => ii.id === listitem.id)
    })
    for (let i = 0, m = itemsids.length; i < m; i++) {
      let item = itemsids[i]
      if (list2.every((listItem => listItem.id !== item.id))) {
        list2.push(item)
      }
    }
    const newOrder = this.makeSortString(list2)
    if (newOrder !== oldOrder) {
      this.order = newOrder
    }
    this.addProp('set', 'order', newOrder)
  }

  public makeSortString(list: {id: number, time: number, option: string}[]) {
    let currentTime = 0
    return list.map(li => {
      // We do have to save new times, which respect the lenth of the elements!
      // We also have to respect pinned items!
      // First get the item - to get the length of the item:
      const item = this.modules.find(m => m.id === li.id)
      // If item is pinned, we do not alter the starttime:
      let itemStartTime = li.option !== 'p' ? Math.max(currentTime, li.time) : li.time
      if (item) {
        currentTime = Math.max(currentTime, itemStartTime + item.duration)
      }
      return `${li.id};${itemStartTime};${li.option.replace(';', '')}`
    }).join(' ')
  }

  public async addItemToOrderAtPosition(position: number, id: number) {
    let list = this.getOrderItemsWithTime()
    list = list.filter(li => li.id !== id)
    const before = list[position - 1]
    let time = 0
    if (before) {
      time = before.time
    }
    list.splice(position, 0, {id: id, time: time, option: ''})
    this.order = this.makeSortString(list)
    await this.addProp('set', 'order', this.order)
  }

  public async addItemToOrderAtTime(time: number, id: number) {
    const inList = this.getOrderItemsWithTime()
    // const filteredList = inList.filter(li => li.id !== id)
    // Look that only available modules are in sort list
    // remove current element
    const filteredList = inList.filter(li => this.modules.some(m => m.hasId(li.id) && !m.hasId(id)))
    // list = list.filter(li => li.id !== id)
    let finish = false
    let i = 0
    const m = filteredList.length
    let position = m
    while(i < m && !finish) {
      if (filteredList[i].time >= time) {
        position = i
        finish = true
      }
      i += 1
    }
    let list = filteredList
    list.splice(position, 0, {id: id, time: time, option: ''})
    let order = this.makeSortString(list)
    this.order = order
    // await this.addProp('set', 'order', order)
    // We do not wait for this prop to be fullfilled, because this speeds up the drag and drop
    await this.addProp('set', 'order', order)
  }

  public async removeItemOfOrder(id: number) {
    let list = this.getOrderItemsWithTime()
    this.order = this.makeSortString(list.filter(oi => oi.id !== id))
    await this.addProp('set', 'order', this.order)
  }

  // Remove Everything connected in seminar mode:
  public async seminarTrash() {
    for(let i = 0, m = this.modules.length; i < m; i++) {
      await this.modules[i].seminarTrash()
    }
    // Remove up, if up is template and remove self
    const url = 'spt/seminarremove'
    if (this.id === -1) { return }
    await Setter(url, {id: this.id})
  }

  public getEditFields(): GenericEditField[] {
    return DayEditFields.map(mef => {
      return {...mef, value: this.getPropV1(mef.key1, mef.key2)}
    })
  }

  public async materialItemReplace(oldId: number, newId: number) {
    const materialItemSorting = PutIdAtPosition(this.materialItemSorting, oldId, newId)
    this.materialItemSorting = materialItemSorting
    await this.addProp('materialItem', 'sorting', materialItemSorting)
  }

  public getMaterialItems(): GenericMaterialItemDTO[] {
    // Sort the output
    const sorted = EqualizeItemListAndSortString(this.materialItemSorting, this.materialItems)
    this.materialItemSorting = sorted.sort
    return sorted.items as GenericMaterialItemDTO[] || []
    // return SortItemsBySortString(this.trainingItemSorting, this.trainingItems) as TrainingItemDTO[]
  }

  public async addMaterialItem(position: number, item: MaterialItemDTO) {
    // Do sorting
    if (item.objectKind === ObjectKind.genericMaterialItem) {
      this.materialItemSorting = PutIdAtPosition(this.materialItemSorting, item.id, position)
      this.addProp('materialItem', 'sorting', this.materialItemSorting)
      if (this.materialItems.find(ti => ti.id === item.id)) {
        return
      }
    }
    // Add item
    const respons = await Setter('spt/linkTargetToParent', {
      parentId: this.id,
      targetId: item.id,
      objectKind: ObjectKind.genericMaterialItem,
      publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar,
    })
    const newItem = new GenericMaterialItemDTO(respons.item)
    this.materialItemSorting = PutIdAtPosition(this.materialItemSorting, newItem.id, position)
    this.addProp('materialItem', 'sorting', this.materialItemSorting)
    /*
    const newItem = new GenericMaterialItemDTO({
      id: -1,
      publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar
    })
    await newItem.saveToServer()
    await newItem.addParentId(this.id)
    await newItem.addUp(item)
    */
    this.materialItems.push(newItem)
  }

  public async trashMaterialItem(item: GenericMaterialItemDTO) {
    // Remove from local list
    this.materialItems = this.materialItems.filter(ti => ti.id !== item.id)
    if (this.publishKind === PublishKind.isSeminar && item.publishKind === PublishKind.isTemplate) {
      this.addToBlacklist(item.id)
    } else {
      const replaceIdRaw = item.getPropV1('link', 'replace')
      if (replaceIdRaw !== '') {
        const replaceId = parseInt(replaceIdRaw, 10)
        this.materialItemReplace(item.id, replaceId)
        // Load the original Data and append it to the modules:
        const url = 'spt/getInfo'
        const data = await Setter(url, {id: replaceId})
        const oldModule = new MaterialItemDTO(data.item as unknown as IMaterialItem)
        this.materialItems.push(oldModule)
      }
    }
    // Remove from Server:
    await item.seminarTrash()
  }
}

export interface IDay extends IGeneric {
  
}

export const DayEditFields: GenericEditField[] = [
  {
    title: 'Fertigstellungsstand',
    key1: 'tags',
    key2: 'workStateTag',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Sprache',
    key1: 'tags',
    key2: 'language',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Themen',
    key1: 'tags',
    key2: 'subjectTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Tagesziele / Erwünschtes Ergebnis',
    key1: 'data',
    key2: 'goals',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Übergeordnete Botschaft für diesen Tag',
    key1: 'data',
    key2: 'takeawaymessage',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Grob-Konzeption für diesen Tag',
    key1: 'data',
    key2: 'roughConcept',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Organisatorisches für diesen Tag',
    key1: 'data',
    key2: 'organizingNotes',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Tagesnotizen',
    key1: 'data',
    key2: 'notes',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
]

export type TimeCell = {
  timeProg: number,
  timeName: string,
  duration: number,
  startTime: number,
  displayTime: boolean,
}
