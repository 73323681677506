import { Setter } from "../../services/ComService";
import { DateStringToDateString } from "../../services/DateTime";
import mainservice from "../../services/MainService";
import { EqualizeItemListAndSortString, PutIdAtPosition } from "../../services/SortString";
import { ObjectKind } from "../models/objectKinds";
import { PublishKind } from "../models/publishKinds";
import { DayDTO } from "./DayDTO";
import { DisplayScope, EditFieldKind, GenericDTO, GenericEditField, IGeneric  } from "./GenericDTO";
import { GenericMaterialItemDTO, IGenericMaterialItem } from "./GenericMaterialItemDTO";
import { IMaterialItem, MaterialItemDTO } from "./MaterialItemDTO";
import { SeminarDayDTO } from "./SeminarDayDTO";

export class SeminarDTO extends GenericDTO {
  standardDeleteMode: 'delete' | 'trash' = 'trash'
  days: SeminarDayDTO[] = []
  constructor(o: ISeminar) {
    super(o)
    this.objectKind = ObjectKind.seminar
  }

  public async getChildrenFromServer() {
    const url = 'spt/getChildren'
    if (this.id === -1) { return }
    const data = await Setter(url, {id: this.id, forceUp: true})
    this.days = this.mixOwnUpChildren(
      data,
      ObjectKind.seminarday,
      true
    ).map(c => new SeminarDayDTO({...c, ...{parentId: this.id}}))
    // this.days = data.items.map((day: ISeminarDay) => new SeminarDayDTO(day))
    // this.orderEveryUnorderedItem(this.days)
    // Materials:
    this.materialItems = this.mixOwnUpChildren(data, ObjectKind.genericMaterialItem).map((item: IGenericMaterialItem) => new GenericMaterialItemDTO(item))
    // this.materialItems = data.materials.items.map((item: IGenericMaterialItem) => new GenericMaterialItemDTO(item))
    // this.materialItems = this.mixOwnUpChildren({upItems: data.upItems, items: data.materials.items}, ObjectKind.genericMaterialItem).map((item: IGenericMaterialItem) => new GenericMaterialItemDTO(item))
  }

  public async addDay(position: number, item: DayDTO) {
    const order = this.getOrderItems()
    const oldPosition = order.findIndex(oi => item.hasId(oi))
    if (oldPosition > -1) {
      if (position > oldPosition) {
        position -= 1
      }
      await this.addItemToOrderAtPosition(position, item.id)
      return
    }
    // Make New Day:
    const respons = await Setter('spt/linkTargetToParent', {
      parentId: this.id,
      targetId: item.id,
      objectKind: ObjectKind.seminarday,
      publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar,
    })
    const newItem = new SeminarDayDTO(respons.item)
    this.days.push(newItem)
    await this.addItemToOrderAtPosition(position, newItem.id)
  }

  public async trashDay(item: SeminarDayDTO) {
    const id = item.id + 0
    // delete Day if it is of type seminar
    await item.seminarTrash()
    // remove from days list:
    this.removeItemOfOrder(id)
    this.days = this.days.filter(d => d.id !== id)
  }

  public getDays() {
    return this.orderedItems(this.days) as SeminarDayDTO[]
  }

  public orderedItems(items: GenericDTO[]) {
    const list = this.order.split(' ')
      .filter(oi => oi !== '')
      .map(o => {
        return {
          id: parseInt(o, 10)
        }
      })
    // remove items from list, which are not in actual items
    // fill in actual items to list
    console.log('orderedItems sort ids', list)
    let out = list
      .filter(li => items.some(ii => ii.hasId(li.id)))
      .map(li => items.find(ii => ii.hasId(li.id)))
    // figure out which items we did forget:
    let forgotten = items
      .filter(ii => out.every(oi => !(oi?.hasId(ii.id))))
    // attach forgotton to the end:
    out = out.concat(forgotten)
    return out
  }

  public async getMaterialList() {
    const url = 'spt/getAllMaterial'
    if (this.id === -1) { return }
    const data = await Setter(url, {id: this.id})
    return data.items.map((material: IMaterialItem) => {
      const item = new MaterialItemDTO(material)
      item.setCountBasedOnReusableCount(parseInt(data.counts[material.id] || '-1', 10 ) )
      return item
    })
  }

  public getEditFields(exList?: GenericEditField[]): GenericEditField[] {
    let list: GenericEditField[] = exList || EventEditFields
    // If this is an event and there are appointments, we blow up props, which are for each appointment:
    const appointments = this.getAppointments()
    if (appointments.length > 0) {
      let newList: GenericEditField[] = []
      list.forEach(i => {
        if (!i.oneForEachAppointment) {
          newList.push(i)
        } else {
          newList.push({...i, ...{
            kind: EditFieldKind.multitag
          }})
          appointments.forEach((a, index) => {
            newList.push({...i, ...{
              title: `${i.title} Zeitraum ${index + 1}`,
              key1: `${i.key1}-${a.key2}`,
              kind: EditFieldKind.multitag
            }})
          })
        }
      })
      list = newList
    }
    return list.filter(item => {
      return mainservice.loginService.user.rights.canRead(item.key1, item.key2)
    }).map(mef => {
      return {...mef, value: this.getPropV1(mef.key1, mef.key2)}
    })
  }

  public async materialItemReplace(oldId: number, newId: number) {
    const materialItemSorting = PutIdAtPosition(this.materialItemSorting, oldId, newId)
    this.materialItemSorting = materialItemSorting
    await this.addProp('materialItem', 'sorting', materialItemSorting)
  }

  public getMaterialItems(): GenericMaterialItemDTO[] {
    // Sort the output
    const sorted = EqualizeItemListAndSortString(this.materialItemSorting, this.materialItems)
    this.materialItemSorting = sorted.sort
    return sorted.items as GenericMaterialItemDTO[] || []
    // return SortItemsBySortString(this.trainingItemSorting, this.trainingItems) as TrainingItemDTO[]
  }

  public async addMaterialItem(position: number, item: MaterialItemDTO) {
    // Do sorting - if item is of type seminar and already there:
    if (item.objectKind === ObjectKind.genericMaterialItem) {
      this.materialItemSorting = PutIdAtPosition(this.materialItemSorting, item.id, position)
      this.addProp('materialItem', 'sorting', this.materialItemSorting)
      if (this.materialItems.find(ti => ti.id === item.id)) {
        return
      }
    }
    // Add item
    const respons = await Setter('spt/linkTargetToParent', {
      parentId: this.id,
      targetId: item.id,
      objectKind: ObjectKind.genericMaterialItem,
      publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar,
    })
    const newItem = new GenericMaterialItemDTO(respons.item)
    this.materialItemSorting = PutIdAtPosition(this.materialItemSorting, newItem.id, position)
    this.addProp('materialItem', 'sorting', this.materialItemSorting)
    /*
    const newItem = new GenericMaterialItemDTO({
      id: -1,
      publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar
    })
    await newItem.saveToServer()
    await newItem.addParentId(this.id)
    await newItem.addUp(item)
    */
    this.materialItems.push(newItem)
  }

  public async trashMaterialItem(item: GenericMaterialItemDTO) {
    // Remove from local list
    this.materialItems = this.materialItems.filter(ti => ti.id !== item.id)
    if (this.publishKind === PublishKind.isSeminar && item.publishKind === PublishKind.isTemplate) {
      this.addToBlacklist(item.id)
    } else {
      const replaceIdRaw = item.getPropV1('link', 'replace')
      if (replaceIdRaw !== '') {
        const replaceId = parseInt(replaceIdRaw, 10)
        this.materialItemReplace(item.id, replaceId)
        // Load the original Data and append it to the modules:
        const url = 'spt/getInfo'
        const data = await Setter(url, {id: replaceId})
        const oldModule = new MaterialItemDTO(data.item as unknown as IMaterialItem)
        this.materialItems.push(oldModule)
      }
    }
    // Remove from Server:
    await item.seminarTrash()
  }

  public getAppointments() {
    return this.getProps('appointment').sort((a, b) => {
      if (a.getV1() === '') {
        return 1
      }
      if (a.getV1() !== '' && a.getV1() < b.getV1()) {
        return -1
      } else if (a.getV2() !== '' && a.getV2() < b.getV2()) {
        return -1
      }
      return 1
    })
  }

  public getStartString() {
    return DateStringToDateString(this.startdate || '')
  }

  public getEndString() {
    return DateStringToDateString(this.enddate || '')
  }
}

export interface ISeminar extends IGeneric {
  id: number | string
}


export const EventEditFields: GenericEditField[] = [
  {
    title: 'Themen',
    key1: 'tags',
    key2: 'subjectTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Zielgruppen',
    key1: 'tags',
    key2: 'targetAudience',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Fertigstellungsstand',
    key1: 'tag',
    key2: 'workStateTag',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Art der Veranstaltung',
    key1: 'tag',
    key2: 'eventKind',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  /*
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  }
  */
  {
    title: 'Ausgangslage / Grundidee',
    key1: 'data',
    key2: 'startingSituation',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Ziele (der Unternehmen) / Erwünschtes Ergebnis',
    key1: 'data',
    key2: 'goals',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Übergeordnete Botschaft',
    key1: 'data',
    key2: 'takeawaymessage',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Grob-Konzeption',
    key1: 'data',
    key2: 'roughConcept',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Organisatorisches',
    key1: 'data',
    key2: 'organizingNotes',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Passende Workbooks',
    key1: 'data',
    key2: 'suitableWorkbooks',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },

]
