import { useEffect, useState } from "react";
import InputB from "../../../components/InputB/InputB";
import ItemCustomerLocationDTO from "../../DTO/ItemCustomerLocationDTO";
import { Plus } from "react-feather";
import ItemsDTO from "../../DTO/ItemsDTO";
import { Status } from "../day/dayView";
import Spinner from "../../../components/Spinner/Spinner";
import CustomerLocation from "./CustomerLocation";
import Menu, { MItem } from "../Menu";
import PlaningToolRoot from "../PlaningToolRoot";

export default function CustomerLocationList(props: {
  selected?: number,
  onSelect?: (id: number, customerLocation: ItemCustomerLocationDTO) => void,
  onTrash?: (id: number) => void,
  onRemove?: (id: number) => void,
  className?: string,
  personId?: number,
  customerId?: number,
  standalone?: boolean,
  noSearch?: boolean,
  rerenderTrigger?: number,
  headline?: string,
}) {
  const [Needle, setNeedle] = useState('')
  const [status, setStatus] = useState(Status.loading)
  const [Items] = useState(new ItemsDTO({
    getUrl: 'spt/customerLocation/list',
    trashUrl: 'spt/customerLocation/delete',
    personId: props.personId || -1,
    customerId: props.customerId || -1,
  }))
  const className = `STCustomerLocationList ${props.className || ''}`
  useEffect(() => {
    setStatus(Status.loading)
    Items.getFromServer().then(() => {
      setStatus(Status.standard)
    })
  }, [props.rerenderTrigger])
  return <PlaningToolRoot standalone={props.standalone} menuItem={MItem.customers}>
  <div className={className}>
    {
      props.standalone &&
      <div><Menu MItem={MItem.customers} /></div>
    }
    <div className='contentHeader'>
      <h2>
        Unternehmensstandorte
      </h2>

      {
        !props.noSearch &&
        <InputB
          className='w3-border'
          returnVal={async (val) => {
            setStatus(Status.loading)
            await Items.setNeedle(val)
            setNeedle(val)
            setStatus(Status.standard)
          }}
          placeholder='Tippen um zu suchen oder neu anzulegen ...'
        />
      }
      {
        Needle !== '' && props.customerId && props.customerId > -1 &&
        <button
          className='positiveButton'
          onClick={async () => {
            const item = new ItemCustomerLocationDTO({
              customerId: props.customerId,
            })
            await item.setName(Needle)
            if (props.onSelect) {
              props.onSelect(item.id, item)
            } else {
              setStatus(Status.loading)
              await Items.getFromServer()
              setStatus(Status.standard)
            }
          }}
        ><Plus /></button>
      }
    </div>
    <div
      className={`CustomerLocationList`}
    >
      {
        status === Status.loading &&
        <Spinner />
      }
      {
        Items.getItems().map((i, index) => {
          return <CustomerLocation
              key={`CustomerLocationListItem-${i.id}`}
              className={`hover ${props.selected === i.id ? 'mark' : ''}`}
              item={i}
              onClick={props.onSelect}
              onTrash={props.onTrash || props.standalone ? async () => {
                if (!window.confirm(`${i.name} wirklich löschen?`)) { return }
                setStatus(Status.loading)
                await Items.trashItem(i.id)
                setStatus(Status.standard)
              } : undefined}
              onRemove={props.onRemove ? () => {
                if (props.onRemove) {
                  props.onRemove(i.id)
                }
              } : undefined}
            />
        })
      }
    </div>
  </div>
  </PlaningToolRoot>
}
