import { parseStringFromServer } from "../../services/TextConverter";
import { ObjectKind } from "../models/objectKinds";
import { DisplayScope, EditFieldKind, GenericDTO, IGeneric, GenericEditField } from "./GenericDTO";

export enum DisplayKind {
  standard,
  gallerie
}

export type MaterialKind = {
  value: string,
  title: string,
  displayKind?: DisplayKind
}

export const Materialkinds = [
  {
    value: 'expandable',
    title: 'Verbrauchsmaterial',
    displayKind: DisplayKind.standard
  },
  {
    value: 'inventory',
    title: 'Bestand',
    displayKind: DisplayKind.standard
  },
  {
    value: 'digitalFlip',
    title: 'Flip',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'digitalHandout',
    title: 'Handout',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'digitalPoster',
    title: 'Plakat',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'digitalPin',
    title: 'Pin',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'digitalFile',
    title: 'Datei',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'collectible',
    title: 'Lernkarte',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'moderationCard',
    title: 'Moderationskarte',
    displayKind: DisplayKind.gallerie
  },
]

export const MaterialEditFields: GenericEditField[] = [
  /*
  {
    title: 'UniqueID',
    key1: 'data',
    key2: 'uniqueId',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.stringDisplay,
    default: 'AUTOFILLUNIQUEID',
  },
  */
  {
    title: 'Name',
    key1: 'tag',
    key2: 'materialNameTag',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Sprache',
    key1: 'tags',
    key2: 'language',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Tags',
    key1: 'tags',
    key2: 'materialTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Themen',
    key1: 'tags',
    key2: 'subjectTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Art Tags',
    key1: 'tags',
    key2: 'materialKindTag',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Art',
    key1: 'data',
    key2: 'materialKind',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.dropDown,
    options: Materialkinds
  },
  {
    title: 'Mehrfachnutzung im Event möglich',
    key1: 'data',
    key2: 'reusableTimes',
    default: '-1',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.dropDown,
    options: [
      {
        value: '-1',
        title: 'Beliebig oft',
      },
      {
        value: '1',
        title: '1',
      },
      {
        value: '2',
        title: '2',
      },
      {
        value: '3',
        title: '3',
      },
      {
        value: '5',
        title: '5',
      },
      {
        value: '10',
        title: '10',
      },
      {
        value: '20',
        title: '20',
      },
    ]
  },
  {
    title: 'Exemplar pro Teilnehmer',
    key1: 'data',
    key2: 'copiesPerPerson',
    default: '0',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.dropDown,
    options: [
      {
        value: '0',
        title: 'Nein',
      },
      {
        value: '1',
        title: 'Ja',
      },
    ]
  },
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Hinweise ⚠️',
    key1: 'data',
    key2: 'hints',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Produktionshinweise 🔨  ',
    key1: 'data',
    key2: 'productionHints',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Transporthinweise 🚚',
    key1: 'data',
    key2: 'transportHints',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Medien',
    key1: 'file',
    key2: 'generic',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.files
  },
  {
    title: 'Länge',
    description: 'Länge in cm',
    key1: 'data',
    key2: 'length',
    unit: 'cm',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Breite',
    description: 'Breite in cm',
    key1: 'data',
    key2: 'width',
    unit: 'cm',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Höhe',
    description: 'Höhe in cm',
    key1: 'data',
    key2: 'height',
    unit: 'cm',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Gewicht',
    description: 'Gewicht in kg',
    key1: 'data',
    key2: 'weight',
    unit: 'kg',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Lagerort',
    key1: 'tag',
    key2: 'materialStorageLocation',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Anzahl (mitnehmen)',
    key1: 'data',
    key2: 'numberNeeded',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Anzahl (Bestand)',
    key1: 'data',
    key2: 'numberStorage',
    scope: DisplayScope.template,
    kind: EditFieldKind.number,
  },
  {
    title: 'Realer einmaliger Gegenstand',
    key1: 'data',
    key2: 'singleton',
    scope: DisplayScope.template,
    kind: EditFieldKind.check,
  },
]

export class MaterialItemDTO extends GenericDTO {
  count: number = -1
  credentials: string[] = []
  appointments: string[] = []
  type: string = ''
  constructor(o: IMaterialItem) {
    super(o)
    this.objectKind = ObjectKind.materialItem
    if (o.info?.credentials) {
      this.credentials = o.info.credentials.split(';').filter(c => c)
      this.appointments = o.info.appointments.map(a => parseStringFromServer(a))
      this.type = o.info.type
    }
  }

  public getEditFields(): GenericEditField[] {
    return MaterialEditFields.map(mef => {
      return {...mef, value: this.getPropV1(mef.key1, mef.key2)}
    })
  }

  public setCountBasedOnReusableCount(times: number) {
    const reuse = parseInt(this.getPropV1('data', 'reusableTimes') || '-1', 10)
    if (reuse === -1) {
      this.count = 1
      return
    }
    this.count = Math.ceil(times / reuse)
  }
}

export interface IMaterialItem extends IGeneric {
  info?: {
    credentials: string,
    appointments: string[],
    type: string,
  }
}
