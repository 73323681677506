import ModulesView from "../modulesView";
import ModuleDTO from "../../DTO/ModuleDTO";
import DayModuleDTO from "../../DTO/DayModuleDTO";
import MaterialsView from "../materialsView";
import mainservice from "../../../services/MainService";
import Spinner from "../../../components/Spinner/Spinner";
import H2Edit from "../../elements/H2Edit";
import { useDrag } from "react-dnd";
import { DayDTO } from "../../DTO/DayDTO";
import { ObjectKind } from "../../models/objectKinds";
import { Copy, Edit, GitMerge, Trash, X } from "react-feather";
import { SeminarDayDTO } from "../../DTO/SeminarDayDTO";
import { useEffect, useState } from "react";
import { PublishKind } from "../../models/publishKinds";
import { EditField } from "../EditField";
import { parseStringToServer } from "../../../services/TextConverter";
import { DropZone } from "../../elements/DropZone";
import { DisplayDate } from "../../../services/DateTime";
import { GenericDTO } from "../../DTO/GenericDTO";
import Credentials from "../Generic/Credentials";
import { RightDrawerContent } from "../RightDrawer";
import '../pt.scss'
import './dayView.scss'
import { EventDTO } from "../../DTO/EventDTO";

export enum DisplayMode {
  template,
  leftSide,
  agenda,
  agendaPlus,
  edit,
  view,
}

export enum Status {
  standard,
  editName,
  editStart,
  dayView,
  agendaView,
  agendaPlusView,
  edit,
  loading,
  details,
  list,
  editItem,
  minimal,
  loadMore,
  new,
}

const DayTabs = [
  {
    status: Status.standard,
    displayName: 'Übersicht',
  },
  {
    status: Status.agendaView,
    displayName: 'Agenda',
  },
  {
    status: Status.agendaPlusView,
    displayName: 'Agenda+',
  },
  {
    status: Status.dayView,
    displayName: 'Stundenansicht',
  },
]

export default function DayView(props: {
  day: DayDTO | SeminarDayDTO,
  displayMode: DisplayMode,
  parentPublishKind: PublishKind,
  parentObjectKind: ObjectKind,
  parent?: GenericDTO,
  position?: number,
  status?: Status,
  activeDay?: number,
  itemCount?: number,
  trash?: (item: SeminarDayDTO) => void,
  templateTrash?: (item: DayDTO) => void,
  addItem?: (position: number, item: DayDTO, type: 'position' | 'template2seminar') => void,
  fullScreenEdit?: () => void,
  rerender?: () => void,
  close?: () => void,
  setStatus?: (status: Status) => void,
  rerenderRightSide?: () => void,
  setEditItem?: (module: ModuleDTO | DayModuleDTO, broadcastKey?: string) => void,
}) {
  // const day = props.day
  const [day, setDay] = useState(props.day)
  const [{isDragging}, drag] = useDrag(() => ({
    type: ObjectKind.day + '',
    item: day,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag(monitor) {
      return status !== Status.editName
    },
  }), [42])

  const [status, setStatus] = useState(Status.loading)
  const [tab, setTab] = useState(props.status !== undefined ? props.status as Status : Status.minimal)
  const [rerender, setRerender] = useState(0)
  const [didInit, setDidInit] = useState(false)
  const broadCastBackKeyAddItem = `DayAddItem-${day.id}`
  const broadCastBackKeyReload = `DayReload-${day.id}`

  async function init(force?: boolean) {
    
    if (!didInit || force) {
      await day.getChildrenFromServer()
      setDidInit(true)
    }
    
    if (props.day.id !== day.id) {
      setStatus(Status.loading)
    }
    // console.log('NEW DAY', props.displayMode, props)
    if (props.displayMode === DisplayMode.edit) {
      await props.day.getFromServer()
    }
    setDay(props.day)
    if (props.activeDay === -Status.agendaView) {
      setTab(Status.agendaView)
      setStatus(Status.agendaView)
      return
    }
    if (props.activeDay === -Status.agendaPlusView) {
      setTab(Status.agendaPlusView)
      setStatus(Status.agendaPlusView)
      return
    }
    if (props.activeDay !== day.id) {
      setTab(Status.minimal)
      setStatus(Status.minimal)
      return
    }
    if (props.activeDay === day.id && status === Status.loading) {
      setStatus(Status.dayView)
    }
  }
  useEffect(() => {
    console.log('new day useeffect')
    init()
  }, [props.activeDay, props.day.id])
  const iconsize = '20px'
  mainservice.registerToBroadcast(`DayEdit-${day.id}`,
    async (key: string, _value: any) => {
      const backData = _value as {position: number, item: ModuleDTO, type: 'time' | 'position' | 'template2seminar' | 'duration'}
      if (key === broadCastBackKeyAddItem) {
        console.log('dayView, got Broadcast', key, _value)
        if (backData.type === 'duration') {
          const module = day.modules.find(m => m.id === backData.item.id)
          if (module) {
            await module.setDuration(backData.position)
          }
        } else {
          await day.addModule(backData.position, backData.item, backData.type)
        }
        setRerender(new Date().getTime())
      } else if (key === broadCastBackKeyReload) {
        // const oldStatus = status
        setStatus(Status.loading)
        await day.getFromServer()
        setStatus(tab)
      }
    }
  )
  let zIndex = undefined
  if (props.itemCount && props.position !== undefined) {
    zIndex = 10 + props.itemCount - props.position
  }
  if (status === Status.loading) {
    return <Spinner />
  }
  return <div
    className={`DayView PTListItem ${(isDragging) ? 'dragging' : 'notDragging'}`}
    title={day.id + ' - pos-' + props.position}
    style={{zIndex: zIndex}}
  >
    <div className='genericContent'>
      <div className={'lineContent'}>
        <div className='stretch' ref={drag}>
          {
            (props.displayMode === DisplayMode.edit || props.displayMode === DisplayMode.leftSide) ?
            <H2Edit
              value={day.getName()}
              onChange={async (rv) => {
                setStatus(Status.editName)
                await day.setName(rv)
                setStatus(tab)
              }}
              usePromt={true}
              oldValue={day.getUpName(true)}
              resetToOldValue={async () => {
                await day.setName('')
                setRerender(rerender + 1)
              }}
            />
          :
            <span>{day.getName()}</span>
          }
        </div>
        {props.parent &&
          <SelectAppointment event={props.parent as EventDTO} day={day} />
        }
        { (props.displayMode === DisplayMode.leftSide || props.displayMode === DisplayMode.edit) &&
          <div className='dont-print rightTools' data-html2canvas-ignore="true">
            {
              DayTabs.map(DayTab => {
                const className = `tab-button-small ${DayTab.status === status ? 'tab-active' : 'w3-white'}`
                return <button
                  key={`DayTab-${day.id}-${DayTab.status}`}
                  className={className}
                  onClick={() => {
                    if (DayTab.status === status) {
                      setStatus(Status.minimal)
                      setTab(Status.minimal)
                      if (props.setStatus) {
                        props.setStatus(Status.minimal)
                      }
                    } else {
                      if (props.setStatus) {
                        if (DayTab.status !== Status.agendaView && DayTab.status !== Status.agendaPlusView) {
                          setStatus(DayTab.status)
                          setTab(DayTab.status)
                        }
                        props.setStatus(DayTab.status)
                      } else {
                        setStatus(DayTab.status)
                        setTab(DayTab.status)
                      }
                    }
                  }}
                >{DayTab.displayName}</button>
              }) 
            }
          </div>
        }
        <div className='rightTools dont-print' data-html2canvas-ignore="true">
          {
            props.fullScreenEdit && day.id > -1 &&
            <Edit
              className='editItem pointer'
              size={iconsize}
              onClick={() => {
                if (props.fullScreenEdit) {
                  props.fullScreenEdit()
                }
              }}
            />
          }
          {
            props.displayMode !== DisplayMode.edit &&
            <GitMerge
              className='delButton editItem pointer'
              size={iconsize}
              onClick={async () => {
                await day.trashPreflight('info')
              }}
            />
          }
          {
            props.displayMode !== DisplayMode.edit && props.displayMode !== DisplayMode.agenda && props.displayMode !== DisplayMode.leftSide &&
            <Copy
              className='delButton editItem pointer'
              size={iconsize}
              onClick={async () => {
                await day.clone(-1, undefined, `${day.name} Kopie`)
                if (props.rerender) {
                  props.rerender()
                }
              }}
            />
          }

          { (props.displayMode === DisplayMode.leftSide || props.displayMode === DisplayMode.edit) &&
            <>
              {
                status === Status.standard &&
                <>
                  <Copy
                    // title={`als Design speichern`}
                    className={`delButton`}
                    onClick={async () => {
                      await day.clone(-1, ObjectKind.day, `${day.name} ${props.parent ? props.parent.name : ''} Kopie ${DisplayDate(new Date())}`, PublishKind.isTemplate, day.id, props.parent?.id || -1)
                      if (props.rerenderRightSide) {
                        props.rerenderRightSide()
                      }
                    }}
                  >
                  </Copy>
                </>
              }
              {
                props.trash && day.id > -1 &&
                <Trash
                  className='delButton'
                  size={iconsize}
                  onClick={async () => {
                    if (day instanceof SeminarDayDTO && props.trash && window.confirm('Tag wirklich löschen?')) {
                      props.trash(day)
                    }
                  }}
                />
              }
            </>
          }
        </div>
      </div>
      
        
        {
          (props.displayMode === DisplayMode.leftSide || props.displayMode === DisplayMode.edit) &&
          <>
            {
              status === Status.standard &&
              <div className='info-block uebersicht'>
                {
                  day.getEditFields().map((ef, index) => {
                    return <EditField
                        key={`ef-${index}`}
                        field={ef}
                        patch={async (efp) => {
                          await day.addProp(efp.key1, efp.key2, parseStringToServer(efp.value || ''))
                          return efp
                        }}
                        item={day}
                        editMode={true}
                      />
                  })
                }
                <h3>Assets</h3>
                <MaterialsView
                  items={day.getMaterialItems()}
                  getItems={async () => {
                    await day.getChildrenFromServer()
                    return day.getMaterialItems()
                  }}
                  identKey={`day-view-${day.id}-`}
                  parentObjectKind={day.objectKind}
                  parentPublishKind={day.publishKind}
                  addItem={async (position, item) => {
                    await day.addMaterialItem(position, item)
                    setRerender(new Date().getTime())
                  }}
                  displayKind={Status.edit}
                  trashItem={async (item) => {
                    await day.trashMaterialItem(item)
                    setRerender(new Date().getTime())
                  }}
                  reloadTrigger={rerender}
                  editItem={(item: GenericDTO, objectKind: ObjectKind) => {
                    mainservice.broadcast('rightDrawerOpen', {
                      id: item.id,
                      broadCastBackKey: broadCastBackKeyReload,
                      contentType: RightDrawerContent.editMaterial
                    })
                  }}
                />
                <Credentials item={day} />
              </div>
            }
            {
              (status === Status.agendaView || status === Status.agendaPlusView || status === Status.dayView) &&
              <>
              <ResetOrderOfModules
                day={day}
                triggerReload={rerender}
                setRerender={async () => {
                  // setStatus(Status.loading)
                  
                  // setStatus(Status.loading)
                  // await init(true)
                  // await day.getFromServer()
                  // await day.getChildrenFromServer()
                  // setRerender(rerender + 1)
                  window.location.href = window.location.href
                }}
              />
              <div className='schedule-block w100'>
                <ModulesView
                  identKey={'day-right-side-' + day.id + '-'}
                  modules={[]}
                  broadcastContext={`day`}
                  trashItem={async (item: DayModuleDTO) => {
                    await day.removeModule(item)
                    setRerender(new Date().getTime())
                  }}
                  addItem={async (position: number, item: ModuleDTO, t: 'time' | 'position' | 'template2seminar' | 'duration') => {
                    console.log('dayView addItem ', position, item, t)
                    await day.addModule(position, item, t)
                    // setStatus(Status.loading)
                    await day.getFromServer()
                    // setStatus(tab)
                    setRerender(new Date().getTime())
                  }}
                  day={day}
                  displayKind={status}
                  parentObjectKind={day.objectKind}
                  parentPublishKind={day.publishKind}
                  setEditItem={(module: ModuleDTO | DayModuleDTO) => {
                    if (props.setEditItem) {
                      // props.setEditItem(module)
                      props.setEditItem(module, broadCastBackKeyAddItem)
                    }
                  }}
                  triggerReload={rerender}
                />
              </div>
              </>
            }
          </>
        }
        {
          props.templateTrash && day.id > -1 &&
          <Trash
            className='delButton A'
            size={iconsize}
            onClick={async () => {
              if (props.templateTrash
                //  && window.confirm('Tag wirklich löschern?')
              ) {
                props.templateTrash(day)
              }
            }}
          />
        }
    </div>
    <DropZone
      label={``}
      position={props.position || 0}
      addItem={async (position, item) => {
        console.log('DropDay item', item)
        if (props.addItem) {
          props.addItem(position, item, 'position')
        }
      }}
      acceptKind={ObjectKind.day}
      className='ptdropzoneHorizontal'
    />
  </div>
}

function SelectAppointment(props: {
  event: EventDTO,
  day: DayDTO,
}) {
  const availableAppointments = props.event.getAppointments()
  const [currentAppointment, setCurrentAppointment] = useState(props.day.getPropV1('selectedAppointment', 'key2'))
  if (!availableAppointments || availableAppointments.length === 0) {
    return null
  }
  return <div className='flex align-items-center'>
    <select
      className='w3-select w3-white'
      onChange={(event) => {
        props.day.addProp('selectedAppointment', 'key2', event.target.value + '')
        setCurrentAppointment(event.target.value + '')
      }}
      value={currentAppointment || '-'}
    >
      {
        [...[{key2: '-'}], ...availableAppointments].map((aI, index) => {
          return <option
            key={`Appointment-select-${props.day.id}-${aI.key2}`}
            value={aI.key2}
          >
            {aI.key2 === '-' ? 'Kein Zeitraum' : `Zeitraum ${index}`}
          </option>
        })
      }
    </select>
  </div>
}

function ResetOrderOfModules(props: {
  day: DayDTO,
  triggerReload: number,
  setRerender: () => void,
}) {
  // const day = props.day
  const [day, setDay] = useState(props.day)
  const [localPropId, setLocalPropId] = useState(day.localPropId('set', 'order'))
  useEffect(() => {
    setDay(props.day)
    const localPropIdNew = props.day.localPropId('set', 'order')
    setLocalPropId(localPropIdNew)
  }, [props.triggerReload, props.day.order, props.day.publishKind, props.day.objectKind])
  if (localPropId < 0 || !day.getUpProp('set', 'order')) {
    console.log('It does not want to work!')
    return null
  }
  return <button
      onClick={async () => {
        await day.removeProp(day.localPropId('set', 'order'))
        props.setRerender()
      }}
    >Lokale Reihenfolge rückgängig machen?</button>
}
