import { Setter } from "../../services/ComService";
import { DisplayScope, EditFieldKind, GenericEditField } from "./GenericDTO";
import ItemDTO, { IItem } from "./ItemDTO";

export default class ItemCustomerDTO extends ItemDTO {
  eventId: number = -1
  personId: number = -1
  constructor(o: IItemCustomer) {
    super(o)
    this.srcAdd = 'spt/customer/add'
    this.srcPatch = 'spt/customer/patch'
    this.srcGet = 'spt/customer/get'
    this.srcAddProp = 'spt/customer/prop/add'
    this.srcPatchProp = 'spt/customer/prop/patch'
    this.srcDelProp = 'spt/customer/prop/del'
    this.eventId = o.eventId || -1
    this.personId = o.personId || -1
    this.editFields = CustomerEditFields
    if (this.eventId > -1) {
      this.id = -2
      this.srcGet = 'spt/event/getCustomer'
      this.optionsGet = {
        id: this.eventId
      }
    } else if (this.personId > -1) {
      this.id = -2
      this.srcGet = 'spt/person/getCustomer'
      this.optionsGet = {
        id: this.personId
      }
    }
  }

  public async addPerson(personId: number) {
    await Setter('spt/person/addCustomer', {
      id: personId,
      customerId: this.id,
    })
  }

  public async removePerson(personId: number) {
    await Setter('spt/person/removeCustomer', {
      id: personId,
      customerId: this.id,
    })
  }

  public async addCustomerLocation(customerLocationId: number) {
    await Setter('spt/customerLocation/addCustomer', {
      id: customerLocationId,
      customerId: this.id,
    })
  }

  public async trashCustomerLocation(customerLocationId: number) {
    await Setter('spt/customerLocation/delete', {
      id: customerLocationId,
      customerId: this.id,
    })
  }

  public async addEvent(eventId: number) {
    await Setter('spt/event/addCustomer', {
      id: eventId,
      customerId: this.id,
    })
  }
}

export interface IItemCustomer extends IItem {
  eventId?: number,
  personId?: number,
}

const CustomerEditFields: GenericEditField[] = [
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Adresse',
    key1: 'data',
    key2: 'address',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
]
