import { Setter } from "../../services/ComService";
import { ObjectKind } from "../models/objectKinds";
import { PersonKind } from "../views/Person/PersonKind";
import { DisplayScope, EditFieldKind, GenericEditField } from "./GenericDTO";
import ItemPersonDTO, { IItemPerson } from "./ItemPersonDTO";
import PropDTO from "./PropDTO";
import { ISeminar, SeminarDTO } from "./SeminarDTO";

export class EventDTO extends SeminarDTO {
  customerId: number = -1
  customerLocationId: number = -1
  seminarId: number = -1
  customerName: string = ''
  customerLocationName: string = ''
  principal?: ItemPersonDTO
  trainers?: ItemPersonDTO[]
  titleSuffix: string = ''
  titlePrefix: string = ''
  appointmentKey: string = ''
  standardDeleteMode: 'delete' | 'trash' = 'trash'

  constructor(o: IEvent) {
    super(o)
    this.objectKind = ObjectKind.event
    this.customerName = o.customerName || ''
    this.customerLocationName = o.customerLocationName || ''
    if (o.principal) {
      this.principal = new ItemPersonDTO(o.principal)
    }
    if (o.trainers) {
      this.trainers = o.trainers.map(t => new ItemPersonDTO(t))
    }
    if (o.titlePrefix) {
      this.titlePrefix = o.titlePrefix
    }
    if (o.titleSuffix) {
      this.titleSuffix = o.titleSuffix
    }
    if (o.appointmentKey) {
      this.appointmentKey = o.appointmentKey
    }
    // console.log('EVENTDTO standardDeleteMode', this.standardDeleteMode, this.getStandardDeleteMode())
  }

  afterInit(o: IEvent) {
    // this.seminarId = o.seminarId || -1
    if (o.up && o.up[0]) {
      this.seminarId = parseInt(o.up[0].id + '', 10)
    }
    this.customerId = o.customerId || -1
    this.customerName = o.customerName || ''
    this.customerLocationName = o.customerLocationName || ''
    this.customerLocationId = o.customerLocationId || -1

  }

  /* Does this belong here? 
  public async getChildrenFromServer() {
    const url = 'spt/getChildren'
    if (this.id === -1) { return }
    const data = await Setter(url, {id: this.id, forceUp: true})
    this.days = this.mixOwnUpChildren(
      data,
      ObjectKind.seminarday,
      true
    ).map(c => new SeminarDayDTO({...c, ...{parentId: this.id}}))
    // this.days = data.items.map((day: ISeminarDay) => new SeminarDayDTO(day))
    this.orderEveryUnorderedItem(this.days)
    // Materials:
    this.materialItems = this.mixOwnUpChildren(data, ObjectKind.genericMaterialItem).map((item: IGenericMaterialItem) => new GenericMaterialItemDTO(item))
  }
  */

  public async addUp(upId: number) {
    const url = 'spt/setUp'
    const id = this.id
    const data = await Setter(url, {
      id: id,
      jd: upId,
      onlyOne: true,
    })
    this.init(data.item)
  }

  public async setStart(s: string) {
    this.startdate = s
    await Setter('spt/setStart', {
      id: this.id,
      startDate: s,
    })
  }

  public async setEnd(s: string) {
    this.enddate = s
    await Setter('spt/setEnd', {
      id: this.id,
      endDate: s,
    })
  }

  public getStartToEndDayCount() {
    const start = this.startdate
    const end = this.enddate
    if (start && end) {
      const s = new Date(start)
      const e = new Date(end)
      const d = Math.round((e.getTime() - s.getTime()) / (1000 * 60 * 60 * 24)) + 1
      if (d === 1) {
        return `1 Tag`
      }
      return `${d} Tage`
    }
    return ''
  }

  public countAppointments() {
    return this.getProps('appointment').length
  }

  public async startAndStopToAppointments() {
    const appointments = this.getProps('appointment')
    let newStart = ''
    let newEnd = ''
    appointments.forEach(a => {
      let aStart = a.getV1()
      if (aStart) {
        if (!newStart || aStart < newStart) {
          newStart = aStart
        }
      }
      let aEnd = a.getV2()
      if (aEnd) {
        if (!newEnd || newEnd < aEnd) {
          newEnd = aEnd
        }
      }
    })
    if (newStart) {
      await this.setStart(newStart)
    }
    if (newEnd) {
      await this.setEnd(newEnd)
    }
  }

  public async addAppointment(key2: string) {
    if (this.countAppointments() === 0) {
      // If we do not have an initial appointment - we create it from the current dates
      await this.addProp('appointment', `${new Date().getTime()}-init`, this.getStartString(), this.getEndString())
    }
    // Now we create a new empty appointment:
    await this.addProp('appointment', key2, '', '')
  }

  public async removeAppointment(key2: string) {
    if (!key2) { return }
    await this.removePropByKeys('appointment', key2)
    await this.removePropByKey1(key2)
    await this.startAndStopToAppointments()
  }

  public getAppointmentStart(key2: string): string {
    const appointmentInfo = this.getProp('appointment', key2) as PropDTO
    if (appointmentInfo && appointmentInfo.getV1()) {
      return appointmentInfo.getV1()
    }
    return (this.countAppointments() < 2) ? this.getStartString() : ''
  }

  public getAppointmentEnd(key2: string) {
    const appointmentInfo = this.getProp('appointment', key2) as PropDTO
    if (appointmentInfo && appointmentInfo.getV2()) {
      return appointmentInfo.getV2()
    }
    return (this.countAppointments() < 2) ? this.getEndString() : ''
  }

  public async setAppointmentStart(key2: string, s: string) {
    await this.addProp('appointment', key2, s, this.getAppointmentEnd(key2) || s || '')
    await this.startAndStopToAppointments()
  }

  public async setAppointmentEnd(key2: string, s: string) {
    await this.addProp('appointment', key2, this.getAppointmentStart(key2) || s || '', s)
    await this.startAndStopToAppointments()
  }

  public dateWarnMsg(): {kind: string, msg: string, className: string} {
    if (!this.startdate) {
      return {
        kind: 'info',
        msg: 'Startdatum angeben',
        className: 'w3-yellow',
      }
    }
    if (!this.enddate) {
      return {
        kind: 'info',
        msg: 'Enddatum angeben',
        className: 'w3-yellow',
      }
    }
    if (this.startdate > this.enddate) {
      return {
        kind: 'error',
        msg: 'Enddatum sollte NACH Startdatum liegen',
        className: 'w3-red',
      }
    }
    return {
      kind: 'okay',
      msg: 'Datumscheck okay',
      className: 'w3-green',
    }
  }

  public async addCustomer(customerId: number) {
    await Setter('spt/event/addCustomer', {
      id: this.id,
      customerId: customerId,
    })
  }

  public async unsetCustomer() {
    await Setter('spt/event/unsetCustomer', {
      id: this.id,
    })
  }

  public async addCustomerLocation(customerLocationId: number) {
    await Setter('spt/event/addCustomerLocation', {
      id: this.id,
      customerLocationId: customerLocationId,
    })
  }

  public async unsetCustomerLocation() {
    await Setter('spt/event/unsetCustomerLocation', {
      id: this.id,
    })
  }

  public async setLocation(locationId: number) {
    await Setter('spt/event/addLocation', {
      id: this.id,
      locationId: locationId,
    })
  }

  public async unsetLocation() {
    await Setter('spt/event/unsetLocation', {
      id: this.id
    })
  }

  public async addPerson(personId: number, personKind?: PersonKind) {
    const personData = await Setter('spt/event/addPerson', {
      id: this.id,
      personId: personId,
      personKind: PersonKind[personKind || PersonKind.generic],
    })
    if (personKind === PersonKind.trainer) {
      const newPerson = new ItemPersonDTO(personData.item)
      const standardTrainerRole = newPerson.getPropV1('trainerProp', 'StandardRole')
      if (standardTrainerRole) {
        await this.addProp('trainerProp', `${personId}`, standardTrainerRole)
      }
    }
  }

  public async removePerson(personId: number, personKind?: PersonKind) {
    await Setter('spt/event/removePerson', {
      id: this.id,
      personId: personId,
      personKind: PersonKind[personKind || PersonKind.generic],
    })
  }

}

export interface IEvent extends ISeminar {
  customerId?: number,
  customerName?: string,
  customerLocationId?: number,
  customerLocationName?: string,
  principal?: IItemPerson,
  trainers?: IItemPerson[],
  titleSuffix?: string,
  titlePrefix?: string,
  appointmentKey?: string,
}

export const EventSpecificEditFields1: GenericEditField[] = [
  {
    title: 'Bemerkungen',
    key1: 'data',
    key2: 'notes',
    className: 'floatRight w3-border-yellow w3-padding w3-round w3-leftbar',
    emojiWhenFilled: 'Hm⚠️',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Auftragsklärung',
    key1: 'tag',
    key2: 'contractState',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.tag,
    // kind: EditFieldKind.multitag,
    oneForEachAppointment: 'plusMain',
  },
  {
    title: 'Bearbeitungsstand',
    key1: 'tag',
    key2: 'workStateTag',
    scope: DisplayScope.template,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Fertigstellungspriorität',
    key1: 'tag',
    key2: 'priorityA',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Fertigstellungsdatum',
    key1: 'data',
    key2: 'dueDate',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.date,
  },
  {
    title: 'Angebotsnummer',
    key1: 'data',
    key2: 'offerId',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Umsatz',
    key1: 'data',
    key2: 'estimatedTurnover',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Umsatz offen',
    key1: 'data',
    key2: 'turnoverMising',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Abrechnungsstatus',
    key1: 'tag',
    key2: 'billingStatus',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Veranstaltungsnummer',
    key1: 'data',
    key2: 'uniqueEventId',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Sprache',
    key1: 'tag',
    key2: 'eventLanguage',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Art der Veranstaltung',
    key1: 'tags',
    key2: 'eventKind',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Themen',
    key1: 'tags',
    key2: 'subjectTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },

]


export const EventSpecificEditFields2: GenericEditField[] = [
  {
    title: 'Ausgangslage',
    key1: 'data',
    key2: 'startingSituation',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Ziele (der Unternehmen) / Erwünschtes Ergebnis',
    key1: 'data',
    key2: 'goals',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Organisatorisches',
    key1: 'data',
    key2: 'organizingNotes',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Logbuch',
    key1: 'file',
    key2: 'eventlogbook',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.files
  },

]
