import { useEffect, useState } from "react";
import Menu, { MItem } from "../Menu";
import { Status } from "../day/dayView";
import Spinner from "../../../components/Spinner/Spinner";
import ItemCustomerLocationDTO from "../../DTO/ItemCustomerLocationDTO";
import mainservice from "../../../services/MainService";
import { EditField } from "../EditField";
import { parseStringFromServer, parseStringToServer } from "../../../services/TextConverter";
import EventList from "../Event/EventList";
import { Edit } from "react-feather";
import InputA from "../../../components/InputA/InputA";
import PersonList from "../Person/PersonList";
import PersonSelect from "../Person/PersonSelect";
import EventSelect from "../Event/EventSelect";
import PlaningToolRoot from "../PlaningToolRoot";
import { RightDrawerContent } from "../RightDrawer";
import BroadcastRightButton from "../../elements/BroadcastRightButton";

export default function CustomerLocationEdit(props: {

}) {
  const id = mainservice.nav.getVal('id') as number
  const [status, setStatus] = useState(Status.loading)
  const [RerenderPersons, setRerenderPersons] = useState(0)
  const [EditId, setEditId] = useState(-1)
  const [Item, setItem] = useState(new ItemCustomerLocationDTO({
    id: id || -1
  }))
  const [UserMsg, setUserMsg] = useState('')
  const broadCastBackKeyPersonAdd = `CustomerPersonAddFor-${Item.id}`
  const broadCastBackKeyEventAdd = `CustomerEventAddFor-${Item.id}`
  mainservice.registerToBroadcast(`CustomerEdit-${Item.id}`,
    async (key: string, _value: any) => {
      console.log('receivedBroadcast', key, _value)
      if (key === broadCastBackKeyPersonAdd) {
        setStatus(Status.loading)
        await Item.addPerson(_value.id as number)
        setStatus(Status.standard)
      }
      if (key === broadCastBackKeyEventAdd) {
        setStatus(Status.loading)
        await Item.addEvent(_value.id as number)
        setStatus(Status.standard)
      }
    }
  )
  function init () {
    Item.getFromServer().then(() => {
      setStatus(Status.standard)
    }).catch((err) => {
      console.log('err', err)
      setUserMsg(err.description)
    })
  }
  useEffect(() => {
    init()
  }, [])
  function WarnMsg() {
    return <>
      {
      UserMsg &&
      <div
        className={`w3-border w3-border-yellow w3-padding`}
      >
        {UserMsg}
        <button
          onClick={() => {
            setUserMsg('')
          }}
          className={`w3-button w3-grey`}
        >OK</button>
      </div>
    }
    </>
  }

  const customerReference = Item.customerId === -1 ? null : <> von <button
    className={`w3-button w3-border`}
    onClick={() => {
      mainservice.navTo([['view', 'spCustomerEdit'], ['id', Item.customerId]])
    }}
  >{Item.customerName}</button>
  </>

  return <PlaningToolRoot standalone={true} menuItem={MItem.customers} topMenu={<h2>Standort {Item.name}{customerReference}</h2>}>
    <WarnMsg />
    {
      status === Status.loading &&
      <Spinner />
    }
    {
      status !== Status.loading &&
      <>
        <div
          className={`contentHeader`}
        >
          <h1>Standort {Item.name}{customerReference}</h1>
        </div>
        <div className='contentPart w3-container'>
          <div className='EditField'>
            <label className='EditFieldLabel'>Name</label>
            <InputA
              value={parseStringFromServer(Item.name || '')}
              returnVal={async (rv) => {
                setEditId(-2)
                await Item.setName(rv)
                setEditId(-1)
              }}
            />
          </div>
          {
            Item.getEditFields().map((ef, index) => {
              return <EditField
                key={`ef-${index}`}
                field={ef}
                patch={async (efp) => {
                  await Item.addProp(efp.key1, efp.key2, parseStringToServer(efp.value || ''))
                  return efp
                }}
                item={Item}
                editMode={true}
              />
            })
          }
        </div>
        <div className='contentPart w3-padding'>
          {
            Item.getEditFields().map((ef, index) => {
              return <EditField
                key={`ef-${index}`}
                field={ef}
                patch={async (efp) => {
                  await Item.addProp(efp.key1, efp.key2, parseStringToServer(efp.value || ''))
                  return efp
                }}
                item={Item}
                editMode={true}
              />
            })
          }
        </div>
        <div className='contentPart'>
          <PersonList
            customerLocationId={Item.id}
            noSearch={true}
            rerenderTrigger={RerenderPersons}
            onRemove={async (id) => {
              await Item.removePerson(id)
              setRerenderPersons(RerenderPersons + 1)
            }}
            headline="Mitarbeiter"
          />
          <BroadcastRightButton
            contentType={RightDrawerContent.selectPerson}
            broadCastBackKey={broadCastBackKeyPersonAdd}
            title={`Weiteren Mitarbeiter zuordnen`}
          />
        </div>
        <div className='contentPart'>
          <EventList
            customerLocationId={Item.id}
            noSearch={true}
            noNew={true}
            rerenderTrigger={RerenderPersons}
            allowRemove={true}
            headline='Veranstaltungen'
          />
          <BroadcastRightButton
            contentType={RightDrawerContent.selectEvent}
            broadCastBackKey={broadCastBackKeyEventAdd}
            title={`Weitere Veranstaltung zuordnen`}
          />
        </div>
      </>
    }
  </PlaningToolRoot>
}
