import { Setter } from "../../services/ComService";
import { DisplayScope, EditFieldKind, GenericEditField } from "./GenericDTO";
import ItemDTO, { IItem } from "./ItemDTO";

export default class ItemCustomerLocationDTO extends ItemDTO {
  eventId: number = -1
  personId: number = -1
  customerId: number = -1
  customerName: string = ''
  constructor(o: IItemCustomerLocation) {
    super(o)
    console.log('ItemCustomerocation DTO ', o, 'after, super')
    this.srcAdd = 'spt/customerLocation/add'
    this.srcPatch = 'spt/customerLocation/patch'
    this.srcGet = 'spt/customerLocation/get'
    this.srcAddProp = 'spt/customerLocation/prop/add'
    this.srcPatchProp = 'spt/customerLocation/prop/patch'
    this.srcDelProp = 'spt/customerLocation/prop/del'
    this.editFields = CustomerLocationEditFields
    if (this.eventId > -1) {
      this.id = -2
      this.srcGet = 'spt/event/getCustomerLocation'
      this.optionsGet = {
        id: this.eventId
      }
    } else if (this.personId > -1) {
      this.id = -2
      this.srcGet = 'spt/person/getCustomerLocation'
      this.optionsGet = {
        id: this.personId
      }
    } else if (this.customerId > -1) {
      this.id = -2
      this.srcGet = 'spt/customer/getCustomerLocation'
      this.optionsGet = {
        id: this.customerId
      }
    }
    console.log('ItemCustomerocation DTO ', this, 'this, done')
  }

  afterInit(o: IItemCustomerLocation) {
    this.eventId = o.eventId || -1
    this.personId = o.personId || -1
    this.customerId = o.customerId || -1
    this.customerName = o.customerName || this.customerName
  }

  public async addPerson(personId: number) {
    await Setter('spt/person/addCustomerLocation', {
      id: personId,
      customerLocationId: this.id,
    })
  }

  public async removePerson(personId: number) {
    await Setter('spt/person/removeCustomerLocation', {
      id: personId,
      customerLocationId: this.id,
    })
  }

  public async addCustomer(customerId: number) {
    await Setter('spt/customer/addCustomerLocation', {
      id: customerId,
      customerLocationId: this.id,
    })
  }

  public async removeCustomer(customerId: number) {
    await Setter('spt/customer/removeCustomerLocation', {
      id: customerId,
      customerLocationId: this.id,
    })
  }

  public async addEvent(eventId: number) {
    await Setter('spt/event/addCustomerLocation', {
      id: eventId,
      customerLocationId: this.id,
    })
  }

  async saveToServer() {
    const url = this.id < 0 ? this.srcAdd : this.srcPatch
    console.log('saveToServer ItemCustomerLocation')
    console.log(url)
    console.log({
      id: this.id,
      name: this.name,
      description: this.description,
      customerId: this.customerId,
    })
    const data = await Setter(url, {
      id: this.id,
      name: this.name,
      description: this.description,
      customerId: this.customerId,
    })
    console.log('data', data)
    this.init(data.item)
  }
}

export interface IItemCustomerLocation extends IItem {
  eventId?: number,
  personId?: number,
  customerId?: number,
  customerName?: string,
}

const CustomerLocationEditFields: GenericEditField[] = [
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Adresse',
    key1: 'data',
    key2: 'address',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
]
