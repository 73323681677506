import { useState } from "react";
import mainservice from "../../../services/MainService";
import PersonList from "../Person/PersonList";
import { PersonKind } from "../Person/PersonKind";
import LocationSelect from "../Location/LocationSelect";
import DateSelect from "../Calendar/DateSelect";
import { EventDTO, EventSpecificEditFields1, EventSpecificEditFields2 } from "../../DTO/EventDTO";
import { Status } from "../day/dayView";
import Spinner from "../../../components/Spinner/Spinner";
import { EditField } from "../EditField";
import { parseStringToServer } from "../../../services/TextConverter";
import BroadcastRightButton from "../../elements/BroadcastRightButton";
import { RightDrawerContent } from "../RightDrawer";
import { DisplayDateFromString, DisplayDateTimeFromString } from "../../../services/DateTime";
import Credentials, {Credential} from "../Generic/Credentials";

export function PlaningDetails(props: {
  event: EventDTO;
}) {
  const Event = props.event;
  const [RerenderPersons, setRerenderPersons] = useState(0);
  const [status, setStatus] = useState(Status.standard);
  const [UserMsg, setUserMsg] = useState('');
  const [rerender, setRerender] = useState(0)
  const broadCastBackKeyPrincipalAdd = `EventPrincipalAddFor-${Event.id}`;
  const broadCastBackKeyTrainerAdd = `EventTrainerAddFor-${Event.id}`;
  const broadCastBackKeyPersonAdd = `EventPersonAddFor-${Event.id}`;
  const broadCastBackKeyLocationAdd = `EventLocationAddFor-${Event.id}`;
  const broadCastBackKeyCustomerAdd = `EventCustomerAddFor-${Event.id}`;
  const broadCastBackKeyCustomerLocationAdd = `EventCustomerLocationAddFor-${Event.id}`;
  const broadCastBackKeySeminarAdd = `EventSeminarAddFor-${Event.id}`;
  mainservice.registerToBroadcast(`EventEdit-${Event.id}`,
    async (key: string, _value: any) => {
      if (key === broadCastBackKeyPersonAdd || key === broadCastBackKeyPrincipalAdd) {
        let personKind: PersonKind = PersonKind.generic
        if (key === broadCastBackKeyPrincipalAdd) {
          personKind = PersonKind.principal
        } else if (key === broadCastBackKeyTrainerAdd) {
          personKind = PersonKind.trainer
        }
        setStatus(Status.loading);
        console.log('person ids', _value.personIds)
        for (let i = 0, m = _value.personIds.length; i < m; i++) {
          await Event.addPerson(_value.personIds[i], personKind);
        }
        setStatus(Status.standard);
      }
      if (key === broadCastBackKeyLocationAdd) {
        setStatus(Status.loading);
        await Event.setLocation(_value.id as number);
        setStatus(Status.standard);
      }
      if (key === broadCastBackKeyCustomerAdd) {
        setStatus(Status.loading);
        Event.customerName = _value.name;
        await Event.addCustomer(_value.id as number);
        setStatus(Status.standard);
      }
      if (key === broadCastBackKeyCustomerLocationAdd) {
        setStatus(Status.loading);
        Event.customerLocationName = _value.name;
        Event.customerLocationId = _value.id;
        await Event.addCustomerLocation(_value.id as number);
        setStatus(Status.standard);
      }
      if (key === broadCastBackKeySeminarAdd) {
        setStatus(Status.loading);
        console.log('SeminarAdd', _value);
        await Event.addUp(_value.id as number);
        setStatus(Status.standard);
      }
    }
  );
  function WarnMsg() {
    return <>
      {UserMsg &&
        <div
          className={`w3-border w3-border-yellow w3-padding`}
        >
          {UserMsg}
          <button
            onClick={() => {
              setUserMsg('');
            }}
            className={`w3-button w3-grey`}
          >OK</button>
        </div>}
    </>;
  }
  if (UserMsg) {
    return <div><WarnMsg /></div>;
  }
  if (status === Status.loading) {
    return <div
      className='EventPlaningDetails'
    >
      <Spinner />
    </div>;
  }
  console.log('Event', Event);
  return <>
    <div className='contentPart w3-padding'>
      {Event.getEditFields(EventSpecificEditFields1).map((ef, index) => {
        return <EditField
          key={`ef-${index}`}
          field={ef}
          patch={async (efp) => {
            await Event.addProp(efp.key1, efp.key2, parseStringToServer(efp.value || ''));
            return efp;
          }}
          item={Event}
          editMode={true}
          rerenderParent={() => {
            console.log('do rerender!')
            setRerender(rerender + 1)
          }}
        />;
      })}
    </div>

    <div className='contentPart'>
      <div className='contentHeader'>
        <h2>Veranstaltungsvorlage</h2>
      </div>
      <div className='w3-padding'>
        {Event.up[0]?.name ? Event.up[0]?.name : 'Keine Vorlage gewählt'}
      </div>
      <BroadcastRightButton
        contentType={RightDrawerContent.selectSeminar}
        broadCastBackKey={broadCastBackKeySeminarAdd}
        validateBeforeClick={() => {
          if(window.confirm('ACHTUNG - Beim wählen einer anderen Veranstaltungsvorlage gehen sämtliche Ablaufinformationen verloren! Trotzdem fortfahren?')) {
            return true
          }
          return false
        }}
        title={`Vorlage wählen`} />
    </div>

    <div className='contentPart'>
      <div className='contentHeader'>
        <h2>Auftraggeber*in</h2>
      </div>
      <PersonList
        eventId={Event.id}
        className=' '
        noSearch={true}
        rerenderTrigger={RerenderPersons}
        onRemove={async (id) => {
          await Event.removePerson(id, PersonKind.principal);
          setRerenderPersons(RerenderPersons + 1);
        }}
        personKind={PersonKind.principal} />
      <BroadcastRightButton
        contentType={RightDrawerContent.selectPerson}
        broadCastBackKey={broadCastBackKeyPrincipalAdd}
        title={`Auftraggeber*in wählen`}
        personKind={PersonKind.principal} />
    </div>

    <div className='contentPart'>
      <div className='contentHeader'>
        <h2>Kunde</h2>
      </div>
      <div className='w3-padding'>{Event.customerName}</div>
      <BroadcastRightButton
        contentType={RightDrawerContent.selectCustomer}
        broadCastBackKey={broadCastBackKeyCustomerAdd}
        title={`Einem Unternehmen zuordnen`} />
    </div>

    <div className='contentPart'>
      <div className='contentHeader'>
        <h2>Kundenstandort</h2>
      </div>
      <div className='w3-padding'>{Event.customerLocationName}, {Event.customerLocationName}</div>
      <BroadcastRightButton
        contentType={RightDrawerContent.selectCustomerLocation}
        broadCastBackKey={broadCastBackKeyCustomerLocationAdd}
        title={`Einem Unternehmensstandort zuordnen`} />
    </div>

    <div className='contentPart'>
      <div className='contentHeader'>
        <h2>Details</h2>
      </div>
      <div className='w3-padding'>
        {Event.getEditFields(EventSpecificEditFields2).map((ef, index) => {
          return <EditField
            key={`ef-${index}`}
            field={ef}
            patch={async (efp) => {
              await Event.addProp(efp.key1, efp.key2, parseStringToServer(efp.value || ''));
              return efp;
            }}
            item={Event}
            editMode={true} />;
        })}
      </div>
    </div>

    <div className='contentPart'>
      <PersonList
        eventId={Event.id}
        className=' '
        noSearch={true}
        rerenderTrigger={RerenderPersons}
        onRemove={async (id) => {
          await Event.removePerson(id, PersonKind.trainer);
          setRerenderPersons(RerenderPersons + 1);
        }}
        personKind={PersonKind.trainer}
        propTrainerTagParent={Event}
        headline="Trainer" />
      <BroadcastRightButton
        contentType={RightDrawerContent.selectPerson}
        broadCastBackKey={broadCastBackKeyTrainerAdd}
        title={`Weiteren Trainer hinzufügen`}
        personKind={PersonKind.trainer} />
    </div>

    <div className='contentPart'>
      <div className='contentHeader'>
        <h2>Zeitraum: {Event.getStartToEndDayCount()}</h2>
      </div>
      <div className='w3-padding'>
        <DateSelect event={Event} />
      </div>
    </div>

    <div className='contentPart'>
      <div className='contentHeader'>
        <h2>Veranstaltungsort</h2>
      </div>
      <div className='w3-padding'>
        <LocationSelect
          Event={Event}
          shortInfo />
      </div>
      <BroadcastRightButton
        contentType={RightDrawerContent.selectLocation}
        broadCastBackKey={broadCastBackKeyLocationAdd}
        title={`Veranstaltungsort einstellen`} />
    </div>

    <div className='contentPart'>
      <PersonList
        eventId={Event.id}
        className=' '
        noSearch={true}
        rerenderTrigger={RerenderPersons}
        onRemove={async (id) => {
          await Event.removePerson(id);
          setRerenderPersons(RerenderPersons + 1);
        }}
        headline="Teilnehmer"
        personTagParent={Event}
      />
      <BroadcastRightButton
        contentType={RightDrawerContent.selectPerson}
        broadCastBackKey={broadCastBackKeyPersonAdd}
        title={`Teilnehmer wählen`}
        personKind={PersonKind.generic} />
    </div>

    <div className='contentPart'>
      <div className='contentHeader'>
        <h2>Backen</h2>
      </div>
      <p className='w3-margin-left w3-margin-top'>Um diese Veranstaltung zu Archivieren, so dass nichts mehr geändert wird - hier auf "Backen" klicken.</p>
      <button
        className='dangerButton w3-margin-left'
        onClick={async () => {
          await Event.bake()
          // setRerender(rerender + 1)
          window.location.reload()
        }}
      >Jetzt backen</button>
      {
        Event.getPropV1('log', 'bakedDate') &&
        <div className='w3-margin-left w3-margin-top'>Zuletzt gebacken <Credential item={Event} key1='log' key2='bakedByUserId' prefix='von ' /><Credential item={Event} key1='log' key2='setUpName' prefix=', ursprünglicher Designname: ' /></div>
      }
    </div>
    <Credentials
      item={Event}
    />
  </>;
}
