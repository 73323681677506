import { Edit, Trash, X } from "react-feather"
import ItemCustomerLocationDTO from "../../DTO/ItemCustomerLocationDTO"
import mainservice from "../../../services/MainService"

export default function CustomerLocation(props: {
  item: ItemCustomerLocationDTO,
  onClick?: (id: number, item: ItemCustomerLocationDTO) => void,
  className?: string,
  onTrash?: (id: number) => void,
  onRemove?: () => void,
}) {
  const item = props.item
  return <div className={`STCustomerLocation PTListItem flex justify-content-space-between ${props.className || ''}`}>
    <button
      className={`w3-button flex-grow textLeft`}
      onClick={() => {
        if (props.onClick) {
          props.onClick(item.id, item)
          return
        }
        mainservice.navTo([['view', 'spCustomerLocationEdit'], ['id', item.id]])
      }}
    >{item.name}</button>
    <button
      className={`w3-button`}
      onClick={() => {
        mainservice.navTo([['view', 'spCustomerLocationEdit'], ['id', item.id]])
      }}
    ><Edit /></button>
    {
      props.onTrash &&
      <button
        className={`w3-button`}
        onClick={() => {
          if (props.onTrash) {
            props.onTrash(item.id)
          }
        }}
      ><Trash /></button>
    }
    {
      props.onRemove &&
      <button
        className={`w3-button`}
        onClick={props.onRemove}
      ><X /></button>
    }
  </div>
}
