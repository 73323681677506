import PropDTO from "./PropDTO"
import { Setter } from "../../services/ComService"
import { ObjectKind, objectKind2Name } from "../models/objectKinds"
import { PublishKind } from "../models/publishKinds"
import { IProp } from "./PropDTO"
import { MaterialItemDTO } from "./MaterialItemDTO"
import { ITag, TagDTO, TagType } from "./TagDTO"
import { parseStringFromServer, parseStringToServer } from "../../services/TextConverter"
import mainservice from "../../services/MainService"
import { PaperOrientation, PaperSizes, TPaperOrientation, TPaperSize } from "../views/PaperSizes"

// import { DayDTO, IDay } from "./DayDTO"

export type TextArraySummaryItem = {
  key1: string,
  key2: string,
  cards: string[],
  name: string,
  deckName: string,
  count: number,
  sourceId: number,
  orientation: string,
  paperFormat: string,
  appointments?: string[],
  credentials?: string,
}
export class GenericDTO {
  id: number = -1
  name: string = ''
  publishKind: PublishKind = PublishKind.isSeminar
  objectKind: ObjectKind = ObjectKind.seminar
  parentId: number = -1
  upId: number = -1
  up: IGeneric[] = []
  props: PropDTO[] = []
  order: string = ''
  materialItems: MaterialItemDTO[] = []
  materialItemSorting: string = ''
  // for permanently hiding thinks, which can not be deleted:
  blackList: number[] = []
  tags: TagDTO[] = []
  startdate?: string
  enddate?: string
  deletedDate?: string
  standardDeleteMode: 'delete' | 'trash' = 'delete'

  public textAreaArraySummary: TextArraySummaryItem[] = []
  
  constructor(o: IGeneric) {
    this.init(o)
  }

  init(o: IGeneric) {
    
    /*
    let standardDeleteMode = 'delete'
    if (o.standardDeleteMode) {
      standardDeleteMode = o.standardDeleteMode
    }
    console.log('OOO', o, o.standardDeleteMode, this.standardDeleteMode)
    this.standardDeleteMode = standardDeleteMode as 'delete' | 'trash'
    */

    if (o.id) {
      this.id = parseInt(o.id + '', 10)
    }
    if (o.name !== undefined) {
      this.name = o.name
    }
    if (o.publishKind !== undefined) {
      this.publishKind = o.publishKind
    }
    if (o.up) {
      this.up = o.up
    }
    if (o.props !== undefined) {
      this.props = o.props.map((prop) => new PropDTO(prop))
    }
    this.materialItemSorting = this.getPropV1('materialItem', 'sorting') || ''
    if (o.tags !== undefined) {
      const upTags = this.gatherUpTags(o)
      this.tags = [...o.tags.map((tag) => new TagDTO(tag)), ...upTags]
    }
    if (o.startDate) {
      this.startdate = o.startDate.date
    }
    if (o.endDate) {
      this.enddate = o.endDate.date
    }
    if (o.deleted) {
      this.deletedDate = o.deleted.date
    } else if (o.deletedDate) {
      this.deletedDate = o.deletedDate
    }
    if (o.parentId && o.parentId > -1) {
      this.parentId = o.parentId
    }
    
    
    this.order = this.getPropV1('set', 'order')
    this.blackList = this.getPropV1('link', 'blackList').split(';').map(s => parseInt(s, 10))
    this.afterInit(o)
  }

  private getUps() {
    return this.upWalker(this)
  }

  private upWalker(item: IGeneric): IGeneric[] {
    let harvestUps: IGeneric[] = item.up || []
    item.up?.forEach(u => {
      harvestUps = [...harvestUps, ...this.upWalker(u as IGeneric)]
    })
    return harvestUps
  }

  public hasId(id: number) {
    if (this.id === id) { return true }
    return this.getUps().some(u => u.id === id)
  }

  public afterInit(o: any) {

  }

  public async getFromServer() {
    const url = 'spt/getInfo'
    if (this.id === -1) { return }
    const data = await Setter(url, {id: this.id})
    this.init(data.item as unknown as IGeneric)
    return data
  }

  public async saveToServer() {
    const url = this.id === -1 ? 'spt/add' : 'spt/rename'

    const data = await Setter(url, {
      id: this.id,
      name: this.name,
      publishKind: this.publishKind,
      objectKind: this.objectKind,
      props: (this.id === -1) ? this.props.map(p => p.get()) : [], // Save props if this is new
    })
    this.init(data.item as unknown as IGeneric)
  }

  public async setName(name: string) {
    this.name = name
    await this.saveToServer()
    return this.get()
  }

  public getName(): string {
    // return this.up[0]?.name || this.name
    return this.name || this.getUpName() || (this.publishKind === PublishKind.isSeminar ? 'gelöschte Referenz' : 'kein Name')
  }

  public getUpName(ifOverwritten?: boolean): string | undefined {
    if (ifOverwritten && this.name === '') { return undefined }
    return this.getUps().find(u => u.name)?.name || undefined
  }

  public get() {
    return {
      id: this.id,
      name: this.name,
    }
  }


  // Remove Everything connected in seminar mode:
  public async seminarTrash() {
    // Remove up, if up is template and remove self
    if (this.id === -1) { return }
    console.log('DOTRASH', this.standardDeleteMode)
    const url = (this.standardDeleteMode === 'delete' || this.deletedDate) ? 'spt/seminarremove' : 'spt/trash'
    await Setter(url, {id: this.id})
  }

  public getStandardDeleteMode() {
    return this.standardDeleteMode
  }

  // Remove Everything connected in seminar mode:
  public async unTrash() {
    // Remove up, if up is template and remove self
    if (this.id === -1) { return }
    const url = 'spt/untrash'
    await Setter(url, {id: this.id})
  }

  private gatherConnections(o: any, root?: boolean, level?: number) {
    const standardLevel = 3
    let r: {id: number, name: string}[] = []
    let indent = ''
    for (let i = 0; i <= (level || standardLevel); i++) {
      indent += '  '
    }
    if (!o) { return [] }
    if (!root && o['name']) {
      const kindName = objectKind2Name(o.objectKind)
      const suffix = (kindName) ? ` (${kindName})` : ''
      r.push({
        id: o.id,
        name: indent + o['name'] + suffix
      })
    }
    function gather(key: string, that: any, level?: number) {
      let part: {id: number, name: string}[] = []
      if(o[key] && o[key].length > 0) {
        o[key].forEach((i: any) => {
          part = part.concat(that.gatherConnections(i, false, (level || standardLevel) + (key === 'down' ? 1 : -1)))
        })
      }
      if (key === 'down') {
        r = r.concat(part.reverse())
      } else {
        r = r.concat(part)
      }
    }
    gather('down', this, level)
    gather('up', this, level)
    gather('children', this, level)
    gather('parents', this, level)
    return r
  }

  public async trashPreflight(context?: 'info' | 'delete') {
    const url = 'spt/removePreflight'
    const data = await Setter(url, {id: this.id})
    const config = {
      'info': {
        'headlineSuffix': ' Verwendungen:',
        'missing': 'Wird hier verwendet:',
        'notNeeded': 'Nutzt diese Resourcen:',
      },
      'delete': {
        'headlineSuffix': 'wirklich löschen?\n Verwendungen:',
        'missing': 'Wird hier fehlen',
        'notNeeded': 'Dies wird hier dann nicht mehr gebraucht:',
      }
    }
    let msg = `${this.name}${config[context || 'delete'].headlineSuffix}\n`
    const fehlenArr = this.gatherConnections(data.downsAndParents, true).map(dd => '- ' + dd.name)
    if (fehlenArr.length === 0) {
      msg += 'Wird nirgendwo verwendet\n'
    } else {
      msg += `${config[context || 'delete'].missing}\n`
      msg += fehlenArr.join('\n')
    }
    const brauchenArr = this.gatherConnections(data.childsAndUps, true)
    if (brauchenArr.length === 0) {
      msg += ''
    } else {
      msg += `\n${config[context || 'delete'].notNeeded}\n`
      msg += brauchenArr.map(dd => '- ' + dd.name).join('\n')
    }
    // msg += data.down.map((dd: any) => (this.gatherDownData(dd) as {name: string}).name).join(',')
    // .map(dd => dd.name).join(', ')
    if (context === 'info') {
      alert(msg)
    } else if (window.confirm(msg)) {
      return true
    }
    return false
  }

  // Remove Everyting connected in template mode:
  public async templateTrash() {
    const okay = await this.trashPreflight()
    if (!okay) {
      return
    }
    const url = (this.standardDeleteMode === 'delete' || this.deletedDate) ? 'spt/remove' : 'spt/trash'
    if (this.id === -1) { return }
    await Setter(url, {id: this.id})
  }

  /**
   * Because of inheritance we have to be careful while changing a prop.
   * For instance - if we are a modification of an item, and we change a prop - the prop cant be changed, if it is from the original - because then we would change the original!
   * @param key1
   * @param key2 
   * @param value1 
   * @param value2 
   * @param unique 
   * @param fileId 
   * @returns 
   */
  public async addProp(key1: string, key2: string, value1: string, value2?: string, unique?:boolean, fileId?: number) {
    const isUnique = (unique === undefined) ? true : unique
    let prop = undefined
    if (isUnique) {
      prop = this.props.find(prop => prop.key1 === key1 && prop.key2 === key2)
      if (prop && prop.id > -1) {
        prop.value1 = parseStringToServer(value1)
        prop.value2 = parseStringToServer(value2 || '')
        return await prop.saveToServer()
      }
    }
    const url = 'spt/addProp'
    prop = await Setter(url, {
      id: this.id,
      unique: isUnique,
      key1: key1,
      key2: key2,
      value1: parseStringToServer(value1),
      value2: parseStringToServer(value2 || ''),
      fileId: fileId,
    })
    if (prop) {
      this.props.push(new PropDTO(prop as IProp))
    }
    return prop
  }

  public propCanBeEdited(id: number): boolean
  {
    return this.props.some(p => p.id === id)
  }

  public async removeProp(id: number) {
    // Only remove Prop, if it is of THIS element! No removing of parent props!
    if (!this.propCanBeEdited(id)) { return }
    this.props = this.props.filter(p => p.id !== id)
    const url = 'spt/removeProp'
    const result = await Setter(url, {id: id})
    // REF Props löschen:
    await this.removePropByKey1(`ref-${id}`)
  }

  public async removePropByKey1(key1: string) {
    const props = this.props.filter(p => p.key1 === key1)
    for(let i = 0, m = props.length; i < m; i++) {
      await this.removeProp(props[i].id)
    }
  }

  public async removePropByKeys(key1: string, key2: string) {
    const props = this.props.filter(p => p.key1 === key1 && p.key2 === key2)
    props.forEach(p => {
      this.removeProp(p.id)
    })
  }

  public getProp(key1: string, key2: string, value1?: string, reverse?: boolean) {
    const props = (reverse) ? this.props.reverse() : this.props
    const prop = props.find(p => p.key1 === key1 && p.key2 === key2 && (!value1 || p.value1 === value1))
    if (prop) { return prop }
    if (!prop) {
      return this.getUpProp(key1, key2)
    }
    return prop
  }

  public localPropId(key1: string, key2: string) {
    const prop = this.props.find(p => p.key1 === key1 && p.key2 === key2)
    if (prop) {
      return prop.id
    }
    return -1
  }

  /**
   * This returns null if there are no up items and undefined if we have ups, but no prop
   * In the "normal" case we receive the up prop
   * @param key1 
   * @param key2 
   * @returns 
   */
  public getUpProp(key1: string, key2: string) {
    const ups = this.getUps()
    if (ups.length === 0) { return null }
    for (let i = 0, m = ups.length; i< m; i++) {
      let up = ups[i]
      let upProp = up.props?.find((p => p.key1 === key1 && p.key2 === key2))
      if (upProp) {
        return upProp
      }
    }
    return undefined
  }

  public getPropById(id: number) {
    return this.props.find(p => p.id === id)
  }

  public getProps(key1: string) {
    let props = this.props.filter(p => p.key1 === key1)
    const ups = this.getUps()
    for (let i = 0, m = ups.length; i< m; i++) {
      let up = ups[i]
      let upProps = up.props?.filter((p => p.key1 === key1)) || []
      if (upProps && upProps.length > 0) {
        props.concat(upProps.map(p => new PropDTO(p)))
      }
    }
    return props
  }

  public getPropV1(key1: string, key2: string, reverse?: boolean) {
    const prop = this.getProp(key1, key2, undefined, reverse)
    return parseStringFromServer(prop?.value1 || '')
  }

  public getPropV2(key1: string, key2: string, reverse?: boolean) {
    const prop = this.getProp(key1, key2, undefined, reverse)
    return parseStringFromServer(prop?.value2 || '')
  }

  public getFiles(key1?: string): PropDTO[] {
    let props = this.props.filter(p => p.file && (!key1 || key1 === 'defaultFile' || p.key1 === key1))
    const ups = this.getUps()
    for (let i = 0, m = ups.length; i < m; i++) {
      let up = ups[i]
      if (up.props) {
        props.push(...up.props.filter(p => p.file && !props.some(ps => ps.id === p.id)).map(p => new PropDTO(p)))
      }
    }
    return props
  }

  public getCurrentFileId() {
    const result = parseInt(this.getPropV1('filesInfo', 'defaultFile', true) || '-1', 10)
    return result
  }

  public getCurrentFile(files?: PropDTO[]): PropDTO | undefined {
    const currentDefaultFilePropId = this.getPropV1('filesInfo', 'defaultFile', true) || '-1'
    const id = parseInt(`${currentDefaultFilePropId}`, 10)
    if (!files) {
      files = this.getFiles()
    }
    let sortValue = 0
    let result: PropDTO | undefined = undefined
    for (let i = 0, m = files.length; i < m; i++) {
      let f = files[i]
      const validFromSort = new Date(f.validFromDate || '').getTime()
      if (f.id === id) {
        result = f
        sortValue = validFromSort + 0
      }
    }
    return result
  }

  public async setCurrentFile(filePropId: number) {
    await this.addProp('filesInfo', 'defaultFile', `${filePropId}`)
  }

  public getOrderItems() {
    return this.order.split(' ').map(oi => parseInt(oi, 10))
  }

  // gibt die Items nach der vorgegebenen Reihenfolge aus. Überzählige werden am Ende ausgegeben:
  public orderedItems(items: {id: number}[]) {
    const list = this.getOrderItems()
    let out = list.filter(li => items.some(ii => ii.id === li)).map(li => items.find(ii => ii.id === li))
    let forgotten = items.filter(ii => out.every(oi => oi?.id !== ii.id))
    out = out.concat(forgotten)
    return out
  }

  public orderEveryUnorderedItem(items: {id: number}[]) {
    const oldOrder = this.order + ''
    let itemsids = items.map(item => item.id)
    let list = this.getOrderItems()
    let log: number[] = []
    // remove listitems not in items:
    let list2 = list.filter(listitem => {
      if (log.indexOf(listitem) > -1) {
        return false
      }
      log.push(listitem)
      return itemsids.indexOf(listitem) > -1
    })
    for (let i = 0, m = itemsids.length; i < m; i++) {
      let itemId = itemsids[i]
      if (list2.indexOf(itemId) === -1) {
        list2.push(itemId)
      }
    }
    const newOrder = list2.join(' ')
    if (newOrder !== oldOrder) {
      this.order = newOrder
    }
    this.addProp('set', 'order', newOrder)
  }

  public async addItemToOrderAtPosition(position: number, id: number) {
    let list = this.getOrderItems().filter(li => li !== id)
    list.splice(position, 0, id)
    this.order = list.join(' ')
    this.addProp('set', 'order', this.order)
  }

  public async removeItemOfOrder(id: number) {
    let list = this.getOrderItems()
    this.order = list.filter(oi => oi !== id).join(' ')
    this.addProp('set', 'order', this.order)
  }

  public async addToBlacklist(id: number) {
    if (this.blackList.indexOf(id) === -1) {
      this.blackList.push(id)
      await this.saveBlackList()
    }
  }

  public isOnBlackList(id: number) {
    return this.blackList.indexOf(id) > -1
  }

  public async removeFromBlacklist(id: number) {
    if (this.blackList.indexOf(id) > -1) {
      this.blackList = this.blackList.filter(b => b !== id)
      await this.saveBlackList()
    }
  }

  public async saveBlackList() {
    await this.addProp('link', 'blackList', this.blackList.join(';'))
  }

  // Why would we do this? - we only need the up children if we have the down child!
  public mixOwnUpChildren(data: {
      items: IGeneric[],
      upItems: IGeneric[],
    },
    objectKind: ObjectKind,
    debug?: boolean,
  ) {
    return data.items.filter((item: IGeneric) => item.objectKind === objectKind)
    // Get Ground data:
    // Determin which children replace which upChildren
    /*
    let children = data.items.filter((item: IGeneric) => item.objectKind === objectKind)
    const replacer = children.map((c: IGeneric): number => {
      const cloneOf = c.props?.find((p: IProp) => p.key1 === 'link' && p.key2 === 'replace')
      if (cloneOf) {
        return parseInt(cloneOf.value1, 10)
      }
      return -1
    }).filter((r: number) => r > -1)
    children = children.filter(c => replacer.indexOf(c.id as number) === -1)
    // Get upChildren and filter them by:
    // - is right kind
    // - is not replaced
    // - is not deleted (blacklist)
    const upChildren = data.upItems.filter((item: IGeneric) => {
      return item.objectKind === objectKind && replacer.indexOf(item.id as number) === -1 && !this.isOnBlackList(item.id as number)
    })
    // return combined children as IGeneric - conversion to correct type has to be done later?
    return children.concat(upChildren)
    */
  }

  public async clone(newParentId: number, objectKind?: ObjectKind, name?: string, publishKind?: PublishKind, origId?: number, parentId?: number) {
    let parentName = ''
    if (this.parentId > -1) {
      const realParentId = mainservice.nav.getVal('id')
      if (realParentId && parseInt(realParentId + '', 10) > -1) {
        const parent = await Setter('spt/getInfo', {id: realParentId})
        parentName = parent.item.name
      }
    }
    const url = 'spt/clone'
    const data = await Setter(url, {
      id: this.id,
      publishKind: publishKind !== undefined ? publishKind : this.publishKind,
      objectKind: objectKind || this.objectKind,
      name: name || (this.name + (parentName ? '(' + parentName + ')' : '')),
      parentId: newParentId || -1,
      origParentId: parentId || -1,
      origId: origId || this.id,
    })
    alert('Klon abgeschlossen')
    // this.init(data.item)
  }

  private gatherUpTags(o: IGeneric) {
    // This has to use every tag!
    const ups = this.getUps()
    let tags: TagDTO[] = [];
    ups.forEach(up => {
      if (up.tags) {
        const upTags = up.tags as TagDTO[]
        tags = [...tags, ...upTags]
      }
    })
    /*
    (o.up || []).forEach((up: IGeneric) => {
      (up.tags || []).forEach(t => {
        tags.push(new TagDTO({...t, ...{fixed: true}}))
      })
    })
    */
    return tags
  }

  // Regular kinds shall be used. But if we need to find all tags of an appointment this has to look different
  public getTagsByKind(kind: TagType, modifier?: string): TagDTO[] {
    modifier = modifier && modifier !== 'tag' ? modifier : ''
    if (modifier) {
      const modifiedTagId = this.getPropV1('tag', modifier)
      return this.tags.filter(t => t.kind === kind && (`${t.id}` === modifiedTagId || modifiedTagId.split(';').some(mt => mt === `${t.id}`)))
    }
    return this.tags.filter(t => t.kind === kind)
  }

  public getFirstTagNameByKind(kind: TagType): string {
    return this.getTagsByKind(kind)[0]?.name || ''
  }

  /**
   * @tag The Tag to set
   * @param singleMode if we are in single Mode
   * @param modifier ???
   */
  public async setTag(tag: TagDTO, singleMode?: boolean, modifier?: string) {
    modifier = modifier && modifier !== 'tag' ? modifier : ''
    // Only do this, if the tag is not already there:
    if (!modifier && this.tags.some(t => t.id === tag.id)) { return false }
    if (singleMode) {
      this.unsetTagIfNotNeeded(modifier)
      this.tags = this.tags.filter(t => t.kind !== tag.kind)
    }
    if (!this.tags.some(t => t.id === tag.id)) {
      this.tags.push(tag)
    }
    if (modifier) {
      let tagShowInfo = (this.getPropV1('tag', modifier) || '')
          .split(';')
      if (tagShowInfo.every(tsi => tsi !== `${tag.id}`)) {
        tagShowInfo.push(`${tag.id}`)
      }
      await this.addProp('tag', modifier, tagShowInfo.join(';'))
    }
    await Setter('spt/setTag', {
      id: this.id,
      tagId: tag.id,
      singleMode: singleMode ? 1 : 0,
    })
  }

  // Removes information for modifier and removes everything, if not needed anymore:
  public async unsetTagIfNotNeeded(modifier: string) {
    if (!modifier) { return }
    const oldTagId = parseInt(this.getPropV1('tag', modifier), 10)
    // count how much other uses are there:
    const allSoftTags = this.getProps('tag').filter(i => i.key2 !== modifier && i.value1 === `${oldTagId}`).length
    if (allSoftTags === 0) {
      // Remove tag entierly, because no one needs it:
      this.tags = this.tags.filter(i => i.id !== oldTagId)
      if (oldTagId) {
        await Setter('spt/unsetTag', {
          id: this.id,
          tagId: oldTagId,
        })
      }
    }
  }

  public async unsetTag(tag: TagDTO, modifier?: string) {
    modifier = modifier && modifier !== 'tag' ? modifier : ''
    if (modifier) {
      // Attention! The order is important!
      await this.unsetTagIfNotNeeded(modifier)
      const tagShowInfo = (this.getPropV1('tag', modifier) || '').split(';').filter(i => this.tags.some(s => `${s.id}` === i)).join(';')
      if (tagShowInfo) {
        await this.addProp('tag', modifier, tagShowInfo)
      } else {
        await this.removePropByKeys('tag', modifier)
      }
      return
    }
    // Only do this, if the tag is not already there:
    this.tags = this.tags.filter(t => t.id !== tag.id)
    await Setter('spt/unsetTag', {
      id: this.id,
      tagId: tag.id,
    })
  }

  public async getTextAreaArraySummaryFromServer() {
    const data = await Setter('spt/getAllTextAreaArray', {
      id: this.id
    })
    this.textAreaArraySummary = data.items.map((i: TextArraySummaryItem) => {
      return {...i, ...{
        cards: i.cards.map(c => {
          return parseStringFromServer(c)
        })
      }}
    })
  }

  public getTextAreaArraySummaryByPaperFormat(options?: {appointment: string}) {
    let out: {
      paperFormat: TPaperSize,
      orientation: TPaperOrientation,
      items: TextArraySummaryItem[],

    }[] = []
    PaperSizes.forEach(s => {
      PaperOrientation.forEach(o => {
        let items = this.textAreaArraySummary.filter(i => (!options || !options.appointment || i.appointments?.some(ia => ia === options.appointment)) && i.orientation === o.key && i.paperFormat === s.key)
        if (items.length > 0) {
          out.push({
            paperFormat: s,
            orientation: o,
            items: items,
          })
        }
      })
    })
    return out
  }

  public getTextAreaArraySaummariesByKey2(key2s: string[]) {
    return key2s.map(key2 => this.textAreaArraySummary.find(i => i.key2 === key2))
  }

  public async bake() {
    if (!window.confirm('Wirklich backen? Das Resultat ist ein völlig eigenständiges Objekt. Wir profitieren dann nicht mehr von Änderungen der Vorlagen.')) {
      return
    }
    await Setter('spt/bake', {
      id: this.id,
    })
    await this.addProp('log', 'bakedDate', new Date().toJSON())
  }

  // To use before deleteing this item - ask for baking downs:
  public async bakeDowns() {
    const downData = await Setter('spt/getDowns', {id: this.id})
    const rawItems = downData.items.map((item: IGeneric) => new GenericDTO(item))
    for (let i = 0, m = rawItems.length; i < m; i++) {
      let item = rawItems[i]
      if (window.confirm(`Soll "${item.name}" gebacken werden?`)) {
        await item.bake()
      }
    }
    if (rawItems.length > 0) {
      alert('Fertig')
    }
  }
}

export interface IGeneric {
  id: number | string
  name?: string
  publishKind?: PublishKind
  objectKind?: ObjectKind
  up?: any[]
  props?: IProp[]
  tags?: ITag[]
  startDate?: {date: string},
  endDate?: {date: string},
  deleted?: {date: string},
  deletedDate?: string,
  parentId?: number,
  standardDeleteMode?: 'delete' | 'trash'
}

export enum DisplayScope {
  hide, // Nobody can see the entry
  template, // template can edit
  seminar, // seminar can edit
  templateAndSeminar, // template and seminar can edit
  read, // Both can only read
  templateAndSeminarRead, // template can edit, seminar can read
  templateReadAndSeminar, // template can read, seminar can edit
}

export enum EditFieldKind {
  stringDisplay,
  string,
  textArea,
  tag,
  multitag,
  number,
  check,
  radio,
  dropDown,
  explanation,
  files,
  date,
  time,
  textAreaArray,
}

export type GenericEditField = {
  title: string,
  key1: string,
  key2: string,
  scope: DisplayScope,
  kind: EditFieldKind,
  className?: string,
  unit?: string,
  options?: {value: string, title: string}[],
  default?: string,
  value?: string,
  description?: string,
  emojiWhenFilled?: string,
  oneForEachAppointment?: string,
}
