/**
 * This function is for globally remembering the current date. So the date information will persist during the session:
 */
class MinDateSaverService {
  minDate= new Date()
  
  set(d: Date) {
    this.minDate = d
  }

  get() {
    return this.minDate
  }
}

export const minDateSaverService = new MinDateSaverService()
